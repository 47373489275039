// src/utils/uploadUtils.ts
import { webSocketService } from '@/utils/websocketService';
import { createFile } from '@/api/files';
import { FileUpload } from '@/models/file';

export interface UploadProgressCallback {
    (progress: number, message?: string): void;
}

export interface UploadSuccessCallback {
    (response: any): void;
}

export interface UploadErrorCallback {
    (error: any): void;
}

export async function uploadFileWithProgress(
    url: string,
    formData: FormData,
    onProgress: (progress: number, message: string) => void,
    onSuccess: (file: FileUpload) => void,
    onError: (error: any) => void
): Promise<void> {
    try {
        formData.append('url', url);
        const file = await createFile(formData, onProgress);
        onSuccess(file);
    } catch (error) {
        onError(error);
    }
}

