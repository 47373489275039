<template>
    <div>Authenticating...</div>
  </template>
  
  <script setup lang="ts">
  import { onMounted } from 'vue';
  import { handleMicrosoftAuthCallback } from '@/services/authService';
  
  onMounted(async () => {
    await handleMicrosoftAuthCallback();
  });
  </script>