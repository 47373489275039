<template>
  <div id="app">
    <nav v-if="isAuthenticated">
      <router-link to="/">Home</router-link>  | 
      <router-link to="/episodes">Episodes</router-link>  | 
      <router-link to="/recordings">Other Recordings</router-link>  | 
      <router-link to="/upload-files">Upload</router-link>  |
      <span disabled to="/captivate-settings"><i>Captivate Settings (coming soon)</i></span>  |
      <router-link v-if="!isAuthenticated" to="/login">Login</router-link>
      <a v-if="isAuthenticated" href="#" @click="logout">Logout</a>
    </nav>
    <div class="header" v-else><h1>Podcast File Management</h1></div>
    <router-view />
    <footer>
      <div class="p-5">&copy; {{ copyrightYear }} Bretty Bret</div>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { initializeMsal } from '@/services/authService';

export default {
  async created() {
    this.copyrightYear = new Date().getFullYear();
    try {
      await initializeMsal();
      await this.$store.dispatch('checkAuth');
    } catch (error) {
      console.error('Error during MSAL initialization', error);
    }

    
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
};

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
};
.header {
  font-size: 2em;
  margin-top: 150px;
};
</style>