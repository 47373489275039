<template>
    <div class="episode-template">
        <h1>{{ pageTitle }}</h1>
    
        <!-- Message Display -->
        <AlertMessage :messages="alertMessages" />
        <div v-if="isLoading">
            <p>Loading...</p>
        </div>
        <div v-else-if="error">
            <p>Error: {{ error }}</p>
        </div>
        <div v-else class='row justify-content-center'>
            <div class="col-lg-8 col-md-12 col-sm-12">
    
                <ul class="nav nav-pills nav-fill mb-5" v-if="!isNew">
                    <li class="nav-item">
                        <button :class="{'nav-link': true, 'active': activeTab === 'general'}" @click="activeTab = 'general'">General</button>
                    </li>
                    <li class="nav-item">
                        <button :class="{'nav-link': true, 'active': activeTab === 'audio'}" @click="activeTab = 'audio'">Audio</button>
                    </li>
                    <li class="nav-item">
                        <button :class="{'nav-link': true, 'active': activeTab === 'youtube'}" @click="activeTab = 'youtube'">YouTube</button>
                    </li>
                    <li class="nav-item">
                        <button :class="{'nav-link': true, 'active': activeTab === 'blog'}" @click="activeTab = 'blog'">Blog</button>
                    </li>
                </ul>
                <div id="generalSection" v-show="activeTab === 'general'">
                    <div class="form-group row">
                        <label for="episodeNumber" class="col-sm-2 col-form-label">Episode #</label>
                        <div class="col-sm-2">
                            <input type="number" @change="checkEpisodeNumber" id="episodeNumber" v-model="episode.episodeNumber" class="form-control" />
                        </div>
                        <div class="col-sm-2"></div>
                        <label for="episodeReleaseDate" class="col-sm-2 col-form-label">Release Date</label>
                        <div class="col-sm-4">
                            <input type="date" id="episodeReleaseDate" v-model="episode.releaseDate" class="form-control" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="notes" class="col-sm-2 col-form-label">Notes</label>
                        <div class="col-sm-10">
                            <textarea id="notes" v-model="episode.notes" class="form-control"></textarea>
                        </div>
                    </div>

                    <!-- Raw File Upload Section -->
                    <div class="form-group row" v-if="!isNew">
                        <div class="col-sm-12">
                            <DirectorySelector thingType="episode" :thingId="episode.id" :incomplete-uploads="incompleteUploads" class="mb-3" v-if="missingFiles" />
                        </div>
                        <label for="rawUpload" class="col-sm-2 col-form-label">Upload Raw Files</label>
                        <div class="col-sm-10">
                            <input type="file" id="rawUpload" data-category="rawFiles" @change="handleFileUpload" class="form-control" multiple />
                        </div>
                        <div class="m-2">
                            <button v-if="episodeRawFiles.length > 0" class="btn btn-sm btn-primary position-relative mt-3" @click="toggleRawFiles">
                                {{ rawFilesCollapse ? 'Show' : 'Hide' }} Raw Files
                                <span v-if="episodeRawFiles.filter(episode => !episode.downloaded).length > 0" class='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                                    {{ episodeRawFiles.filter(episode => !episode.downloaded).length }}
                                    <span class="visually-hidden">undownloaded files

                                    </span>
                                </span>
                            </button>
                        </div>
                        <div :class="{collapse: rawFilesCollapse}" id="rawFiles">
                            <FileEmbed v-for="file in episodeRawFiles" :key="file.id" :fileId="file.id" @changed="loadEpisodeData" @delete="fileDeleted(file)" />
                        </div>
                    </div>

                </div>
                <div id="blogSection" v-show="activeTab === 'blog'">
    
                    <!-- Blog Post Editor -->
                    <div class="form-group row" v-if="!isNew">
                        <label for="blogPost" class="col-sm-2 col-form-label">Blog Post Content</label>
                        <div class="col-sm-10">
                            <Editor api-key="ydhijh9fxytgbxlc55cjza5m3mk2j5y6774xyw4sc5pnx2h3" v-model="episode.blogPostContent" :init="editorConfig"></Editor>
                        </div>
                    </div>

                    <div class="transcript-container row form-group" v-if="!isNew">
                        <label class="col-sm-2 col-form-label">Transcript</label>
                        <div class="transcript-content col-sm-10">
                            <iframe :src="episode.transcript" frameborder="0" width="100%" height="500"></iframe>
                        </div>
                    </div>

                </div>
                <div id="audioSection" v-show="activeTab === 'audio'">
    
                   

                    <div :class="{collapse: captivateCollapse}" id="captivate" v-if="!isNew">

                        <div class="form-group row" v-if="captivateEpisode?.id">
                            <div style="width: 100%; height: 200px; margin-bottom: 20px; border-radius: 6px; overflow: hidden;">
                                <iframe ref="captivateEmbed" style="width: 100%; height: 200px;" frameborder="no" scrolling="no" allow="clipboard-write" seamless :src="captivateEmbedUrl"></iframe>
                            </div>

                            <form class="m-2" @submit.prevent="updateCaptivateEpisode">

                                <div class="form-group row">
                                    <label for="captivateStatus" class="col-sm-2 col-form-label">Status</label>
                                    <div class="col-sm-10">
                                        <input type="text" id="captivateStatus" v-model="captivateEpisode.status" class="form-control" readonly />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="captivatePublishedDate" class="col-sm-2 col-form-label">Published Date</label>
                                    <div class="col-sm-10">
                                        <input type="text" id="captivatePublishedDate" v-model="captivateEpisode.published_date" class="form-control" readonly />
                                    </div>
                                </div>

                                <!-- Editable fields -->
                                <div class="form-group row">
                                    <label for="captivateTitle" class="col-sm-2 col-form-label">Title</label>
                                    <div class="col-sm-10">
                                        <input type="text" id="captivateTitle" v-model="captivateEpisode.title" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="captivateDescription" class="col-sm-2 col-form-label">Description</label>
                                    <div class="col-sm-10">
                                        <Editor api-key="ydhijh9fxytgbxlc55cjza5m3mk2j5y6774xyw4sc5pnx2h3" v-model="captivateEpisode.shownotes" :init="editorConfig"></Editor>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="captivateAuthor" class="col-sm-2 col-form-label">Author</label>
                                    <div class="col-sm-10">
                                        <input type="text" id="captivateAuthor" v-model="captivateEpisode.author" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="captivateEpisodeArt" class="col-sm-2 col-form-label">Episode Art</label>
                                    <div class="col-sm-10">
                                        <img :src="captivateEpisode?.episode_art" alt="Episode Art" width="150px" class="img-fluid" />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="captivateMedia" class="col-sm-2 col-form-label">Final Media</label>
                                    <div class="col-sm-10">
                                        <audio controls :src="captivateEpisode?.media_url" class="audio-fluid">Your browser does not support the audio element.</audio>
                                    </div>
                                </div>

                                

                            </form>
                        </div>
                        <div v-else class="m-2">
                             <!-- Episode Audio Title -->
                            <div class="form-group row">
                                <label for="episodeAudioTitle" class="col-sm-2 col-form-label">Episode Title</label>
                                <div class="col-sm-10">
                                    <input type="text" id="episodeAudioTitle" v-model="episode.audioTitle" class="form-control" />
                                </div>
                            </div>

                            <!-- Episode Description -->
                            <div class="form-group row">
                                <label for="episodeAudioDescription" class="col-sm-2 col-form-label">Episode Description</label>
                                <div class="col-sm-10">
                                    <textarea id="episodeAudioDescription" v-model="episode.audioDescription" class="form-control"></textarea>
                                </div>
                            </div>
                            <!-- Final File Upload Section -->
                            <div class="form-group row" v-if="!isNew">
                                <label for="finalUploadAudio" class="col-sm-2 col-form-label">{{ episodeFinalAudioFile ? '' : 'Upload ' }} Final Audio File</label>
                                <div class="col-sm-10">
                                    <input v-if="!episodeFinalAudioFile" type="file" id="finalUploadAudio" data-category="finalAudioFile" @change="handleFileUpload" class="form-control" multiple />
                                    <div class="uploaded-files">
                                        <FileEmbed v-if="episodeFinalAudioFile && episodeFinalAudioFile.id" :key="episodeFinalAudioFile.id" :fileId="episodeFinalAudioFile.id" @changed="loadEpisodeData" @delete="fileDeleted(episodeFinalAudioFile)" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" v-if="!captivateIdFieldCollapse">
                                <label for="captivateId" class="col-sm-2 col-form-label">Captivate ID</label>
                                <div class="col-sm-10">
                                    <input type="text" v-model="episode.captivateId" class="form-control" />
                                </div>
                            </div>
                            <button @click="captivateIdFieldCollapse = !captivateIdFieldCollapse" v-if="captivateIdFieldCollapse && !captivateEpisode?.id" class="btn btn-sm btn-success m-2">Enter Captivate Id</button>
                            <button @click="saveEpisode" v-if="!captivateIdFieldCollapse" class="btn btn-sm btn-success m-2">Attach Captivate Episode</button>
                            <button @click="createCaptivateEpisode" class="btn btn-sm btn-warning m-2">Create Captivate Episode</button>
                        </div>
                    </div>
                </div>
                <div id="youtubeSection" v-show="activeTab === 'youtube'">
    
                    <!-- Final Thumbnail Upload Section -->
                    <div class="form-group row" v-if="!isNew">
                        <label for="finalUploadThumbnails" class="col-sm-2 col-form-label">{{ episodeYoutubeThumbnails && episodeYoutubeThumbnails.length > 0 ? '' : 'Upload ' }} Thumbnails</label>
                        <div class="col-sm-10">
                            <input type="file" id="finalUploadThumbnails" data-category="youtubeThumbnails" @change="handleFileUpload" class="form-control" multiple />
                            <div class="uploaded-files" v-if="episodeYoutubeThumbnails && episodeYoutubeThumbnails.length > 0">
                                <FileEmbed v-for="file in episodeYoutubeThumbnails" :key="file.id" :fileId="file.id" @changed="loadEpisodeData" @delete="fileDeleted(file)" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="thumbnailIdeas" class="col-sm-2 col-form-label">Thumbnail Ideas</label>
                        <div class="col-sm-10">
                            <textarea id="thumbnailIdeas" v-model="episode.thumbnailIdeas" class="form-control"></textarea>
                        </div>
                    </div>
                    <!-- Episode YouTube Title -->
                    <div class="form-group row">
                        <label for="episodeYoutubeTitle" class="col-sm-2 col-form-label">YouTube Title</label>
                        <div class="col-sm-10">
                            <input type="text" id="episodeYoutubeTitle" v-model="episode.youtubeTitle" class="form-control" />
                        </div>
                    </div>
                    <!-- Episode Youtube Description -->
                    <div class="form-group row">
                        <label for="youtubeDescription" class="col-sm-2 col-form-label">YouTube Description</label>
                        <div class="col-sm-10">
                            <textarea id="youtubeDescription" v-model="episode.youtubeDescription" class="form-control"></textarea>
                        </div>
                    </div>

                    <!-- Final File Upload Section -->
                    <div class="form-group row" v-if="!isNew">
                        <label for="finalUploadVideo" class="col-sm-2 col-form-label">{{ episodeFinalVideoFile ? '' : 'Upload ' }}Final Video File</label>
                        <div class="col-sm-10">
                            <input v-if="!episodeFinalVideoFile" type="file" id="finalUploadVideo" data-category="finalVideoFile" @change="handleFileUpload" class="form-control" multiple />
                            <div class="uploaded-files">
                                <FileEmbed v-if="episodeFinalVideoFile && episodeFinalVideoFile.id" :key="episodeFinalVideoFile.id" :fileId="episodeFinalVideoFile.id" @changed="loadEpisodeData" @delete="fileDeleted(episodeFinalVideoFile)" />
                            </div>
                        </div>
                    </div>

                    <!-- YouTube Final URL -->
                    <div class="form-group row" v-if="!isNew">
                        <label for="youtubeFinalLink" class="col-sm-2 col-form-label">YouTube Link</label>
                        <div class="col-sm-10">
                            <input type="url" id="youtubeFinalLink" v-model="episode.finalYoutubeVideo" class="form-control" />
                        </div>
                    </div>
                    <h4 v-if="analyticsLink"><a :href="analyticsLink" target="_blank">YouTube Analytics</a></h4>
                </div>
    
                <!-- Save Button -->
                <div class="form-group row m-5 d-flex justify-content-between align-items-center">
                    <div class="col-9">
                        <button @click="saveEpisode" class="btn btn-primary">{{ submitText }}</button>
                    </div>
                    <div class="col-3">
                        <button v-if="episode" @click="deleteThisEpisode" class="btn btn-sm btn-danger">Delete Episode</button>
                    </div>
    
                </div>
    
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
                <ActivityLog v-if="activities.length > 0" :logs="activities" @update="refreshActivities" type="item" :limit="5" />
            </div>
    
        </div>
    
    </div>
    <span class='font-size-sm'>Created: {{ created }} | Updated: {{ updated }}</span>
</template>

<script setup lang="ts">
import { formatLocalDate } from '@/utils/normalize';
import {
    ref, onMounted, watch,
    computed
} from 'vue';
import {
    Episode
} from '@/models/episode';
import {
    FileUpload
} from '@/models/file';
import Editor from '@tinymce/tinymce-vue'
import {
    fetchEpisodeById,
    createOrUpdateEpisode,
    deleteEpisode,
    fetchEpisodeByEpisodeNumber,
    getFilesForEpisode,
    fetchCaptivateEpisodeByEpisodeId,
    updateCaptivateEpisodeByEpisodeId,
    createNewCaptivateEpisode
} from '@/api/episodes';
import {
    fetchActivities
} from '../api/activityLog';
import { ActivityLog as ActivityLogObject } from '@/models/activityLog';
import AlertMessage from '@/components/AlertMessage.vue'; // Import the AlertMessage component
import { AlertMessageData } from '../models/alertMessageData';
import { uploadFileWithProgress } from '@/utils/uploadUtils';
import FileEmbed from '@/components/FileEmbed.vue';
import ActivityLog from './ActivityLog.vue';
import { UploadData, checkForIncompleteUploads, resumeUpload } from '@/api/files';
import { fetchFileById } from '@/api/files';
import { CaptivateEpisode, CaptivateMedia } from '@/models/captivate';
import DirectorySelector from './DirectorySelector.vue';
import { useRoute, useRouter } from 'vue-router';
import { useAsyncState } from '@vueuse/core';

const router = useRouter();
const route = useRoute();

const episodeId = computed(() => route.params.id);
const isNew = computed(() => !episodeId.value);
const episodeRawFiles = ref([] as FileUpload[]);
const episodeFinalAudioFile = ref({} as FileUpload);
const episodeFinalVideoFile = ref({} as FileUpload);
const episodeYoutubeThumbnails = ref([] as FileUpload[]);
const captivatePreSaveEpisode = ref({} as CaptivateEpisode);
const captivateEpisode = ref({} as CaptivateEpisode);
const captivateEpisodeMedia = ref({} as CaptivateMedia);
const captivateEmbedUrl = ref('');
const incompleteUploads = ref([] as UploadData[]);
const pageTitle = ref('Create New Episode');
const submitText = ref('Create Episode');
const analyticsLink = ref(undefined as string | undefined);
const alertMessages = ref([] as AlertMessageData[]);
const activities = ref([] as ActivityLogObject[]);
const created = ref(formatLocalDate(new Date()));
const updated = ref(formatLocalDate(new Date()));
const rawFilesCollapse = ref(true);
const captivateCollapse = ref(false);
const captivateIdFieldCollapse = ref(true);
const activeTab = ref('general');
const missingFiles = ref(false);
const editorConfig = ref({
    height: 500,
    menubar: false,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
});

const { state: episode, isLoading, error, execute: loadEpisodeData} = useAsyncState(async () => {
    let e = {
            id: undefined,
            episodeNumber: undefined,
            releaseDate: undefined,
            audioTitle: '',
            audioDescription: '',
            youtubeTitle: '',
            youtubeDescription: '',
            youtubeVideoId: '',
            transcript: '',
            blogPostContent: '',
            thumbnailIdeas: '',
            notes: '',
            created: new Date(),
            updated: new Date(),
        } as Episode;
    if (episodeId.value) {
        const identifier = episodeId.value as string;
        if (identifier.match(/^[0-9a-fA-F-]{36}$/)) {
            e = await fetchEpisodeById(identifier);
        } else if (!isNaN(Number(identifier))) {
            e = await fetchEpisodeByEpisodeNumber(Number(identifier));
        } else {
            console.error('Invalid episode identifier:', identifier);
            return e;
        }
        if (e.captivateId && e.id) {
            const fetchedCaptivateEpisode = await fetchCaptivateEpisodeByEpisodeId(e.id) as CaptivateEpisode;
            captivatePreSaveEpisode.value = {...fetchedCaptivateEpisode}
            captivateEpisode.value = fetchedCaptivateEpisode;
            captivateEmbedUrl.value = `https://player.captivate.fm/episode/${captivateEpisode.value.id}/`;
        }
        // Fetch episode data and populate the episode object
        pageTitle.value = `Episode ${e.episodeNumber}`;
        submitText.value = 'Update Episode';
        created.value = formatLocalDate(e.created);
        updated.value = formatLocalDate(e.updated);
        activities.value = await fetchActivities('episode', e.id);
        const episodeFiles = await getFilesForEpisode(identifier);
        console.log('Episode Files:', episodeFiles);
        episodeRawFiles.value = episodeFiles.filter((file: any) => file.category === 'rawFiles').sort((a, b) => {
            // First, prioritize files with type 'video/mp4'
            if (a.type === 'video/mp4' && b.type !== 'video/mp4') return -1;
            if (a.type !== 'video/mp4' && b.type === 'video/mp4') return 1;
            
            // Then, sort alphabetically by name if both are of the same type or neither is 'video/mp4'
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            
            return 0;
        });
        episodeFinalAudioFile.value = episodeFiles.find((file: any) => file.category === 'finalAudioFile') as FileUpload;
        episodeYoutubeThumbnails.value = episodeFiles.filter((file: any) => file.category === 'youtubeThumbnails');
        episodeFinalVideoFile.value = episodeFiles.find((file: any) => file.category === 'finalVideoFile') as FileUpload;
        analyticsLink.value = e.youtubeVideoId ? `https://studio.youtube.com/video/${e.youtubeVideoId}/analytics/tab-overview/period-default` : undefined;
    }
    return e
}, {} as Episode, { immediate: true });

const loadMissingFilesMessage = async () => {
    // const now = new Date();
    // const created = new Date(episode.value.created);
    if (!episode.value) return;
    const promises = episodeRawFiles.value.filter((file: any) => !file.uploadComplete).map((file: any) => fetchFileById(file.id));
            // console.log('Missing files:', missing);
    const missing = (await Promise.all(promises)).map((file: FileUpload) => file.originalFileName);
    const rawFilesShould = [
            {name: `CAM 1`, type: 'video/mp4'},
            {name: `CAM 2`, type: 'video/mp4'},
            {name: `CAM 3`, type: 'video/mp4'},
            {name: `CAM 4`, type: 'video/mp4'},
            {name: `MIC 1`, type: 'audio/wav'},
            {name: `MIC 2`, type: 'audio/wav'},
            {name: 'CAM 1', type: 'audio/wav'},
            {name: 'CAM 2', type: 'audio/wav'},
            {name: 'CAM 3', type: 'audio/wav'},
            {name: 'CAM 4', type: 'audio/wav'},
        ]
        const rawFilesDoes = [...episodeRawFiles.value]
        console.log('Raw files:', rawFilesDoes);
        if (rawFilesDoes.length === 0) {
            missingFiles.value = true;
            const fileMessage: AlertMessageData = {
                text: `No raw files uploaded for Episode ${episode.value.episodeNumber}.`,
                type: 'error',
            };
            if (episode.value.driveDirectoryString) fileMessage.text += ` You can navigate to ${episode.value.driveDirectoryString} to upload them.`
            if (alertMessages.value.filter(m => m.text.includes('No raw files')).length === 0) {
                alertMessages.value.push(fileMessage);
            } else {
                const messageToUpdate = alertMessages.value[0] // Ensure reactivity
                messageToUpdate.text = fileMessage.text;
            }
            return;
        }
        console.log(rawFilesDoes[0]);
        const rawFileOriginalString = rawFilesDoes[0]?.originalFileName || '';
        const mainFileRegex = /^([A-Za-z0-9\s-]+) (MIC \d+|CAM \d+) (\d{2})?\.(mp4|wav)$/;
        const mainFileMatch = rawFileOriginalString.match(mainFileRegex);
        if (mainFileMatch) {
            const missingShould = rawFilesShould.filter(should => !rawFilesDoes.some(does => does.name.includes(should.name) && does.type === should.type)).map(file => `${mainFileMatch[1]} ${file.name} ${mainFileMatch[3] ? mainFileMatch[3] : ''}.${file.type.split('/')[1]}`);
            missingShould.forEach(should => {
                if (missing.includes(should)) return;
                missing.push(should);
            })
        }

    if (missing.length > 0) {
        missingFiles.value = true
        incompleteUploads.value = await checkForIncompleteUploads();
        const fileMessage: AlertMessageData = {
            text: `Missing files: ${missing.join(', ')}.`,
            type: 'error',
        };
        if (episode.value.driveDirectoryString) fileMessage.text += ` You can navigate to ${episode.value.driveDirectoryString} to upload them.`
        if (alertMessages.value.filter(m => m.text.includes('Missing')).length === 0) alertMessages.value.push(fileMessage);
    }
}




const toggleCaptivate = () => {
    captivateCollapse.value = !captivateCollapse.value;
}

const toggleRawFiles = () => {
    rawFilesCollapse.value = !rawFilesCollapse.value;
}

const refreshActivities = async () => {
    activities.value = await fetchActivities('episode', episodeId.value as string);
    loadMissingFilesMessage();
}

const clearFileInput = (inputId: string) => {
    const fileInput = document.getElementById(inputId) as HTMLInputElement || undefined; // Get the file input element
    if (!fileInput) return;
    const newFileInput = document.createElement('input'); // Create a new input element
    newFileInput.type = 'file';
    newFileInput.id = fileInput.id;
    newFileInput.name = fileInput.name;
    newFileInput.className = fileInput.className;
    newFileInput.style.cssText = fileInput.style.cssText;
    fileInput.parentNode?.replaceChild(newFileInput, fileInput); // Replace the old input with the new one
    console.log('Done');
}

const checkEpisodeNumber = async (event: any) => {
    const episodeNumber = parseInt(event.target.value);
    try {
        await fetchEpisodeByEpisodeNumber(episodeNumber);
        alert('An episode with this number already exists. Please enter a different number.');
        event.target.value = '';
    } catch (error) {
        // No episode with this number exists, which is what we want
    }
}

const deleteThisEpisode = async () => {
    if (episode.value) {
        const confirmDelete = confirm('Are you sure you want to delete this episode?');
        if (confirmDelete) {
            await deleteEpisode(episode.value.id as string);
            router.push('/episodes');
        }
    }
}

const fileDeleted = (file: FileUpload) => {
    const fileMessage: AlertMessageData = {
        text: `File ${file.name} deleted successfully.`,
        type: 'success',
    };
    alertMessages.value.push(fileMessage);
    alertMessages.value = [...alertMessages.value]; // Ensure reactivity
    loadEpisodeData();
    loadMissingFilesMessage();
}

const uploadFileWithRetry = async (uploadObject: any) => {
    await uploadFileWithProgress(
        uploadObject.url,
        uploadObject.formData,
        uploadObject.onProgress,
        uploadObject.onSuccess,
        uploadObject.onError
    );
}

const retryUpload = async (uploadObject: any) => {
    try {
        await uploadFileWithRetry(uploadObject);
    } catch (error) {
        console.error('Error retrying file upload:', error);
    }
}

const handleFileUpload = async (event: any) => {
    if (!episode.value) {
        alert('Please save the episode before uploading files.');
        return;
    }
    const files = event.target.files;
    const category = event.target.dataset.category;
    const url = `${process.env.VUE_APP_API_URL}/api/files`;
    console.log('Files:', files);
    if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const originalFileName = file.name;
            console.log('Original file name:', originalFileName);
            const mainFileRegex = /^([A-Za-z0-9\s-]+) (MIC \d+|CAM \d+) (\d{2})?\.(mp4|wav)$/;
            const mainFileMatch = file.name.match(mainFileRegex);
            console.log('Main file match:', mainFileMatch);
            const newName = mainFileMatch ? `Episode ${episode.value.episodeNumber} - ${mainFileMatch[2]}.${mainFileMatch[4]}` : file.name;
            const incompleteUpload = incompleteUploads.value.find((upload: UploadData) => upload.filePath.includes(newName));
            if (incompleteUpload) {
                const resume = confirm(`Do you want to resume uploading ${file.name}?`);
                if (resume) {
                    const fileMessage: AlertMessageData = {
                        text: `Resuming upload of ${file.name}...`,
                        type: 'progress',
                        progress: incompleteUpload?.progress || 0,
                    };
                    alertMessages.value.push(fileMessage);

                    await resumeUpload(
                        incompleteUpload as UploadData,
                        file,
                        (progress) => {
                            fileMessage.progress = progress;
                            fileMessage.text = `Resuming upload for ${newName}...`;
                        },
                        () => {
                            fileMessage.type = 'success';
                            fileMessage.text = `File ${file.name} resumed and uploaded successfully.`;
                            clearFileInput(event.target.id);
                            setTimeout(() => {
                                loadEpisodeData();
                                loadMissingFilesMessage();
                            }, 3000);
                        },
                        (error) => {
                            fileMessage.type = 'error';
                            fileMessage.text = `Error resuming upload for file ${file.name}: ${error}`;
                        }
                    );
                    continue;  // Skip the rest of the loop and move to the next file
                }
            }
            
            const fileMessage: AlertMessageData = {
                text: `Uploading ${newName}...`,
                type: 'progress',
                progress: 0,
            };
            alertMessages.value.push(fileMessage);

            const formData = new FormData();
            formData.append('file', file);
            formData.append('attachedToId', episode.value.id?.toString() || '');
            formData.append('name', newName);
            formData.append('attachedToType', 'episode');
            formData.append('category', category);
            formData.append('originalFileName', originalFileName);

            try {
                const uploadObject = {
                url: `https://${process.env.VUE_APP_AWS_STORAGE_BUCKET_NAME}.s3.us-east-1.amazonaws.com/uploads/episode/${episode.value.id}/${newName}`,
                formData,
                onProgress: (progress: number, message?: string) => {
                    fileMessage.progress = progress;
                    fileMessage.text = message || `Uploading ${file.name}`;
                },
                onSuccess: (response: any) => {
                    fileMessage.type = 'success';
                    fileMessage.text = `File ${file.name} uploaded successfully.`;
                    clearFileInput(event.target.id);
                    setTimeout(() => {
                        loadEpisodeData();
                    }, 3000);
                },
                onError: (error: any) => {
                    fileMessage.type = 'error';
                    fileMessage.text = `Error uploading file ${formData.get('name')}: ${error}`;
                    fileMessage.retryObject = uploadObject; // Store the object for retry
                }
            };
                await uploadFileWithRetry(uploadObject);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    }
}

const createCaptivateEpisode = async () => {
    if (!episode.value) return;
    if (episode.value.id && episode.value.releaseDate && episode.value.episodeNumber) {
        const createdCaptivateEpisode: CaptivateEpisode = {
            title: `Episode ${episode.value.episodeNumber?.toString().padStart(3, '0')} - ${episode.value.audioTitle}`,
            status: 'Draft',
            date: new Date(episode.value.releaseDate).toISOString().split('T')[0] + ' 03:00:00',
            shownotes: episode.value.audioDescription,
            summary: episode.value.audioDescription,
            itunes_subtitle: '',
            author: 'David Bayer',
            episode_art: '',
            explicit: '',
            episode_type: 'full',
            episode_season: '0',
            episode_number: episode.value.episodeNumber?.toString(),
            donation_link: '',
            donation_text: '',
            link: `https://www.davidbayer.com/podcast/episode-${episode.value.episodeNumber.toString().padStart(3, '0')}`,
            itunes_block: 'false',
        };
        captivateEpisode.value = await createNewCaptivateEpisode(episode.value.id, createdCaptivateEpisode);
        captivateEmbedUrl.value = `https://player.captivate.fm/episode/${captivateEpisode.value.id}/`;
        loadEpisodeData();
    }
}

const updateCaptivateEpisode = async () => {
    if (!episode.value) return;
    if (episode.value.captivateId && captivateEpisode.value && captivatePreSaveEpisode.value && episode.value.id) {
        console.log('Updating captivate episode:', captivateEpisode.value);

        let episodeToSend = {
            title: captivateEpisode.value.title,
            status: captivateEpisode.value.status,
            date: captivateEpisode.value.published_date,
            media_id: captivateEpisode.value.media_id,
            shownotes: captivateEpisode.value.shownotes,
            summary: captivateEpisode.value.summary,
            itunes_subtitle: captivateEpisode.value.itunes_subtitle,
            author: captivateEpisode.value.author,
            episode_art: captivateEpisode.value.episode_art,
            explicit: captivateEpisode.value.explicit,
            episode_type: captivateEpisode.value.episode_type,
            episode_season: captivateEpisode.value.episode_season,
            episode_number: captivateEpisode.value.episode_number,
            donation_link: captivateEpisode.value.donation_link,
            donation_text: captivateEpisode.value.donation_text,
            link: captivateEpisode.value.link,
            itunes_block: captivateEpisode.value.itunes_block,

        }
        
        console.log('Episode to send:', episodeToSend);
        // Perform the update with only the modified fields
        await updateCaptivateEpisodeByEpisodeId(episode.value.id, episodeToSend);

        // Notify the user of the successful update
        const fileMessage: AlertMessageData = {
            text: `Captivate episode ${episode.value.captivateId} updated successfully.`,
            type: 'success',
        };
        alertMessages.value.push(fileMessage);
        alertMessages.value = [...alertMessages.value]; // Ensure reactivity
        loadEpisodeData();
    }
};

const saveEpisode = async (event: any) => {
    if (!episode.value) return;
    const button = event.target as HTMLButtonElement;
    button.disabled = true;
    button.textContent = 'Saving...';
    try {
        if (captivateEpisode.value.id && captivateEpisode.value.date) {
            let confirmCaptivateSave = false;
            const now = new Date();
            const publishDate = new Date(captivateEpisode.value.date) || null;
            if (publishDate && now > publishDate) confirmCaptivateSave = true
            if (confirmCaptivateSave) {
                const saveConfirmation = confirm('Do you want to update the Captivate episode with the new data?');
                if (saveConfirmation) {
                    await updateCaptivateEpisode();
                }
            }
            
            episode.value.audioTitle = captivateEpisode.value.title;
            episode.value.audioDescription = captivateEpisode.value.shownotes;

        }
        episode.value = await createOrUpdateEpisode(episode.value);
        const fileMessage: AlertMessageData = {
            text: `Episode ${episode.value.episodeNumber} saved successfully.`,
            type: 'success',
        };
        alertMessages.value.push(fileMessage);
        button.textContent = 'Saved!';

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // Fade out after 5 seconds
        setTimeout(() => {
            button.textContent = submitText.value;
            button.disabled = false;
            router.push(`/episodes/${episode.value.id}`);
            loadEpisodeData();
        }, 1000);

    } catch (error) {
        const fileMessage: AlertMessageData = {
            text: `Episode ${episode.value.episodeNumber} was not saved.`,
            type: 'error',
        };
        alertMessages.value.push(fileMessage);
        alertMessages.value = [...alertMessages.value]; // Ensure reactivity

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
}


onMounted(async () => {
    await loadEpisodeData();
    if (episodeId.value) await loadMissingFilesMessage();
});

watch(() => route.params.id, (id, oldId) => {
    if (id !== oldId) {
        loadEpisodeData();
    }
});

</script>

<style scoped>
h1,
h2 {
    margin: 1em
}

.episode-template {
    max-width: 95%;
    margin: 0 auto;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.quill-editor {
    height: 300px;
}

.transcript-container {
    margin-top: 20px;
}

.transcript-content {
    max-height: 1000px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    white-space: pre-wrap;
}

.new-file {
    font-weight: bold;
    color: red;
}
.nav {
    font-weight: bold
}
</style>
