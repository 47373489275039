// src/services/webSocketService.ts

export interface ProgressUpdate {
    path: string;
    progress: any;
    type: "progress" | "success" | "error" | "info";
}

type ProgressUpdateCallback = (data: ProgressUpdate) => void;

export class WebSocketService {
    private socket: WebSocket | null = null;
    private listeners: ProgressUpdateCallback[] = [];

    constructor() {
        this.connect();
    }

    private connect() {
        const websocketUrl = process.env.VUE_APP_WEBSOCKET_URL || 'ws://localhost:3000/ws';
        console.log('Connecting to WebSocket:', websocketUrl);
        this.socket = new WebSocket(websocketUrl);

        this.socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        this.socket.onmessage = async (event: MessageEvent) => {
            if (event.data instanceof Blob) {
                const text = await event.data.text(); // Convert Blob to text
                const data: ProgressUpdate = JSON.parse(text); // Parse JSON
                this.notifyListeners(data);
            } else {
                const data: ProgressUpdate = JSON.parse(event.data);
                this.notifyListeners(data);
            }
        };

        this.socket.onclose = () => {
            console.log('WebSocket connection closed');
            // Optionally, you can try to reconnect here
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    }

    public addListener(callback: ProgressUpdateCallback) {
        this.listeners.push(callback);
    }

    public removeListener(callback: ProgressUpdateCallback) {
        this.listeners = this.listeners.filter(listener => listener !== callback);
    }

    private notifyListeners(data: ProgressUpdate) {
        this.listeners.forEach(listener => listener(data));
    }

    public sendMessage(data: ProgressUpdate) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(data));
        }
    }
}

// Export a single instance of the service
export const webSocketService = new WebSocketService();