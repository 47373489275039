<template>
    <div class="attachments-embed">
        <h2>Attachments</h2>
        <ItemList :items="attachments" :columns="attachmentColumns" @item-selected="openAttachment" />

        <!-- Form to add new attachments -->
        <form @submit.prevent="addAttachment" class="form-control">
            <div class="form-group row">
                <label class="col-2 col-md-2 col-sm-12 col-form-label" for="attachmentName">Name</label>
                <div class="col-10 col-md-10 col-sm-12">
                    <input class="form-control" type="text" v-model="newAttachment.name" id="attachmentName" placeholder="Attachment Name" required>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-2 col-md-2 col-sm-12 col-form-label" for="attachmentLink">Link</label>
                <div class="col-10 col-md-10 col-sm-12">
                    <input class="form-control" type="text" v-model="newAttachment.link" id="attachmentLink" placeholder="Attachment Link" required>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-2 col-md-2 col-sm-12 col-form-label" for="attachmentDescription">Description</label>
                <div class="col-10 col-md-10 col-sm-12">
                    <textarea class="form-control" v-model="newAttachment.description" id="attachmentDescription" placeholder="Attachment Description"></textarea>
                </div>
            </div>
            <input type="hidden" v-model="newAttachment.attachedToId">
            <button class="btn btn-sm btn-success" type="submit">Add Attachment</button>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { fetchAttachmentsForThing, createAttachment } from '@/api/attachments';
import { Attachment } from '@/models/attachment';
import ItemList from './ItemList.vue';

const props = defineProps<{
  thingType: string;
  thingId: string;
}>();

const attachments = ref<Attachment[]>([]);
const newAttachment = ref<Attachment>({
  attachedToId: props.thingId,
  attachedToType: props.thingType,
  name: '',
  link: '',
  description: '',
} as Attachment);

const attachmentColumns = {
  name: {
    label: 'Name',
    format: (value: string) => value,
  },
  description: {
    label: 'Description',
    format: (value: string) => value,
  },
};

const openAttachment = (attachment: Attachment) => {
  window.open(attachment.link, '_blank');
};

const fetchAttachments = async () => {
  attachments.value = await fetchAttachmentsForThing(props.thingType, props.thingId);
};

const addAttachment = async () => {
  await createAttachment(newAttachment.value);
  await fetchAttachments();
  newAttachment.value = {
    attachedToId: props.thingId,
    attachedToType: props.thingType,
    name: '',
    link: '',
    description: '',
  } as Attachment;
};

onMounted(fetchAttachments);
</script>

<style scoped>
.attachments-embed {
    margin: 20px 0;
}
form {
    margin-top: 20px;
}
.form-group {
    margin-bottom: 10px;
}
</style>