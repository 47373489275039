import * as msal from '@azure/msal-browser';
import store from '@/store';
import router from '@/router';
import axios from 'axios';

export let msalInstance: msal.PublicClientApplication;

export async function initializeMsal() {

    msalInstance = new msal.PublicClientApplication({
        auth: {
            clientId: process.env.VUE_APP_AZURE_CLIENT_ID!,
            authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANT_ID}`,
            redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI || 'http://localhost:8080/auth/callback',
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
        },
    });
    await msalInstance.initialize();
}

export async function login() {
    const loginRequest = {
        scopes: ['user.read'],
    };

    try {
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        // console.log('Login response:', loginResponse); // Log the entire response
        // Set the active account
        msalInstance.setActiveAccount(loginResponse.account);

        // After login, acquire the access token
        const tokenResponse = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: loginResponse.account,
        });
        // console.log('Acquired token:', tokenResponse.accessToken);
        return loginResponse; // This will contain the account information and tokens
    } catch (error) {
        console.error('Login failed', error);
        throw error;
    }
}

export async function acquireToken() {
    const tokenRequest = {
        scopes: ['user.read'],
    };

    try {
        const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
        // console.log('Acquired token:', tokenResponse.accessToken); // Log the token
        return tokenResponse.accessToken;
    } catch (error) {
        console.error('Token acquisition failed', error);
        throw error;
    }
}

export async function handleRedirectCallback() {
    try {
        const response = await msalInstance.handleRedirectPromise();
        return response; // This will contain the account information and tokens
    } catch (error) {
        console.error('Handle redirect failed', error);
        throw error;
    }
}

export function getAccount() {
    const accounts = msalInstance.getAllAccounts();
    return accounts.length > 0 ? accounts[0] : null;
}

export function logout() {
    localStorage.removeItem('authToken');
}

export async function handleMicrosoftAuthCallback() {
    try {
        // Extract the authorization code from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');

        if (authCode) {
            // Send the authorization code to your backend using Axios
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/callback`,
                {
                    code: authCode
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true, // Ensure cookies are sent with the request
                });

            const data = response.data;
            if (data.success) {
                const { token, user } = data;

                // Store the token and user info in Vuex
                store.commit('setAuthToken', token);
                store.commit('setMsalAccount', user);

                // Redirect to the home page or another protected route
                router.push('/');
            } else {
                console.error('Authentication failed:', data.message);
                // Handle authentication failure, e.g., redirect to an error page or show a message
            }
        }
    } catch (error) {
        console.error('Error during authentication:', error);
        // Handle any errors that occurred during the authentication process
    }
}