import { get } from 'http';
import { Attachment } from "@/models/attachment";
import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

export async function createAttachment(attachment: Attachment): Promise<Attachment> {
    const response = await axios.post(`${apiUrl}/api/attachments`, attachment);
    return response.data;
}

export async function fetchAttachment(id: string): Promise<Attachment> {
    const response = await axios.get(`${apiUrl}/api/attachments/${id}`);
    return response.data;
}

export async function fetchAttachmentsForThing(type: string, typeId: string): Promise<Attachment[]> {
    try {
        const response = await axios.get(`${apiUrl}/api/attachments/${type}/${typeId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching attachments:', error);
        throw error;
    }

}