import { ActivityLog } from "@/models/activityLog";

// src/api/episodes.ts

import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;

// Fetch all episodes
export const fetchActivities = async (thingType?: string, thingId?: string): Promise<ActivityLog[]> => {
    console.log('Fetching activities for:', thingType, thingId);
    try {
        let response
        if (thingType && thingId) {
            response = await axios.get(`${apiUrl}/api/activity-logs/${thingType}/${thingId}/activity-logs`);
        } else if (thingType) {
            response = await axios.get(`${apiUrl}/api/activity-logs/${thingType}/activity-logs`);
        } else {
            response = await axios.get(`${apiUrl}/api/activity-logs`);
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching episodes:', error);
        throw error;
    }
};