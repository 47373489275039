<template>
  <div v-if="!isAuthenticated" class="">
    <MicrosoftLoginButton />
    <form v-if="email === 'something'" @submit.prevent="handleLogin">
      <div class="form-group flex row">
        <label class="col-sm-4 col-form-label" for="email">Email:</label>
        <div class="col-sm-6">
          <input class="form-control" type="email" v-model="email" required />
        </div>
      </div>
      <div class="form-group flex row">
        <label class="col-sm-4 col-form-label" for="password">Password:</label>
        <div class="col-sm-6">
          <input class="form-control" type="password" v-model="password" required />
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
     
      <p v-if="error" class="text-danger">{{ error }}</p>
    </form>
    
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MicrosoftLoginButton from '@/components/MicrosoftLoginButton.vue';

// Set up router and store
const router = useRouter();
const store = useStore();

// Reactive state
const email = ref('');
const password = ref('');
const error = ref('');

// Computed properties
const isAuthenticated = computed(() => store.getters.isAuthenticated);

// Methods
const handleLogin = async () => {
  const success = await store.dispatch('login', { email: email.value, password: password.value });
  if (success) {
    router.push('/');
  } else {
    error.value = 'Invalid email or password';
  }
};

// Redirect if already authenticated
watch(isAuthenticated, (auth) => {
  if (auth) {
    router.push('/');
  }
});

// Expose variables and methods to the template
</script>

<style scoped>
.login-container {
  max-width: 50%;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.form-group {
  margin-bottom: 10px;
}
</style>