import { Episode } from '@/models/episode';
import { Recording } from '@/models/recording';
import { FileUpload } from '@/models/file';

// Type guard for Episode
export function isEpisode(item: any): item is Episode {
    return (item as Episode).episodeNumber !== undefined;
}

// Type guard for Recording
export function isRecording(item: any): item is Recording {
    return (item as Recording).dueDate !== undefined;
}

export function isUpload(item: any): item is FileUpload {
    return (item as FileUpload).url !== undefined;
}