<template>
  <div class="item-list">
    <h2 v-if="title">{{ title }}</h2>
    <table class="table table-striped">
    <thead>
      <tr>
        <!-- Use columns if available; otherwise, use keys from the first item -->
        <th v-for="(column, key) in columnHeaders" :key="key">
          {{ column.label || key }}
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- Generate table rows -->
      <tr v-for="(item, index) in items" :key="item.id" @click="selectItem(item)" class="pointer-cursor" @mouseover="hoverIndex = index"  @mouseleave="hoverIndex = null" :class="{ 'hovered-row pointer-cursor': hoverIndex === index && type !== 'item'}">
        <!-- Generate table cells with custom formatting if provided, otherwise just display the value -->
        <td v-for="(column, key) in columnHeaders" :key="key">
        <span v-if="column.format && columnFancyFormat">
          <span v-html="column.format(item)"></span>
        </span>
        <span v-if="column.format">
          <span v-html="column.format(item[key])"></span>
        </span>
        <span v-else>
          <span v-html="item[key]"></span>
        </span>
      </td>
      </tr>
    </tbody>
  </table>
  </div>
</template>
  
<script setup lang="ts">
import { computed, ref } from 'vue';
import { defineProps, defineEmits } from 'vue';

interface Column {
  label: string;
  format?: (value: any) => string;
}

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  columns: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  items: {
    type: Array as () => any[],
    required: true,
    default: () => [],
  },
  columnFancyFormat: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits(['item-selected']);

const hoverIndex = ref<number | null>(null);

const columnHeaders = computed<Record<string, Column>>(() => {
 if (props.columns) {
  return props.columns;
 } else if (props.items.length > 0) {
  return Object.keys(props.items[0] as object).reduce((acc, key) => {
    acc[key] = { label: key };
    return acc;
  }, {} as Record<string, Column>);
 }
  return {};
});

const selectItem = (item: any) => {
  emit('item-selected', item);
};
</script>
  
  <style scoped>
  .item-list {
    max-width: 80%;
    margin: 0 auto;
  }
  .item-list ul {
    list-style-type: none;
    padding: 0;
  }
  .item-list li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  .item-list li:hover {
    background-color: #f9f9f9;
  }
  .pointer-cursor {
    cursor: pointer;
  }
  .hovered-row {
  border: 2px solid #007bff; /* Change border on hover */
}
  </style>