<template>
  <div class="microsoft-login-button">
    <button type="button" class="btn btn-primary" @click="handleLogin">Login with Microsoft</button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

// Define the login function
const handleLogin = async () => {
  try {
    await store.dispatch('login'); // Dispatch the Vuex action
    // Optionally navigate or handle post-login logic here
    // router.push('/some-route');
  } catch (error) {
    console.error('Error logging in:', error);
  }
};
</script>

<style scoped>
.microsoft-login-button {
  margin: 40px;
}
.microsoft-login-button button {
  padding: 10px 20px;
  font-size: 1.5em
}
</style>