// HomeView.vue (or wherever you have a logout button)

<template>
<div>
    <h1>DBB Podcast File Management System</h1>
    <div class="justify-items-center row">
        <div class="col-lg-9 col-md-12 col-sm-12" v-if="incompleteEpisodes.length > 0 || incompleteUploads.length > 0 || incompleteRecordings.length > 0">
            <IncompleteItemsTable v-if="incompleteUploads.length > 0" title="Incomplete Uploads" type="uploads" :items="incompleteUploads" :columns="uploadColumns" @item-selected="navigateToItem" @update="updateItem" />
            <IncompleteItemsTable v-if="incompleteEpisodes.length > 0" title="Incomplete Episodes" type="episodes" :items="incompleteEpisodes" :columns="episodeColumns" @item-selected="navigateToItem" @update="updateItem" />
            <IncompleteItemsTable v-if="incompleteRecordings.length > 0" title="Incomplete Recordings" type='recordings' :items="incompleteRecordings" :columns="recordingColumns" @item-selected="navigateToItem" @update="updateItem" />
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
            <ActivityLog :logs="activities" :limit="10" @update="refreshActivities" />
        </div>

    </div>
</div>
</template>

<script setup lang="ts">
import {
    ref,
    computed,
    onMounted
} from 'vue';
import {
    useRouter
} from 'vue-router';
import {
    useStore
} from 'vuex';
import IncompleteItemsTable from '@/components/IncompleteItemsTable.vue';
import ActivityLog from '@/components/ActivityLog.vue';
import {
    fetchRecordings,
    fetchRecordingById
} from '@/api/recordings';
import {
    fetchEpisodeById,
    fetchEpisodes
} from '@/api/episodes';
import {
    fetchUndownloadedFiles,
    fetchIncompleteUploads,
    UploadData
} from '@/api/files';
import {
    fetchActivities
} from '@/api/activityLog';
import {
    Episode
} from '@/models/episode';
import {
    Recording
} from '@/models/recording';
import {
    FileUpload
} from '@/models/file';
import {
    ActivityLog as LogActivity
} from '@/models/activityLog';
import {
    isEpisode,
    isRecording,
    isUpload
} from '@/typeGuards';

// Use refs to manage state
const episodes = ref < Episode[] > ([]);
const recordings = ref < Recording[] > ([]);
const uploads = ref < FileUpload[] > ([]);
const undownloadedFiles = ref < FileUpload[] > ([]);
const activities = ref < LogActivity[] > ([]);
const dataLoaded = ref(false);

const router = useRouter();
const store = useStore();

// Define columns configuration with type safety
const uploadColumns = {
    filePath: {
        label: 'File Name',
        format: (value: string) => value.split('/').pop() || '',
    },
    percentProgress: {
        label: 'Progress',
        format: (value: number) => {
            const color = value > 70 ? '#4caf50' : value > 30 ? '#ffeb3b' : '#f44336'; // Green, Yellow, Red based on progress
            return `
        <div style="
          background-color: ${color};
          width: ${value < 15 ? 15 : value}%;
          height: 20px;
          color: white;
          text-align: center;
          line-height: 20px;
          padding: 0;
          box-sizing: border-box;">
          ${value}%
        </div>`;
        },
    },
};

const episodeColumns = {
    audioTitle: {
        label: 'Audio Title'
    },
    youtubeTitle: {
        label: 'YouTube Title'
    },
    releaseDate: {
        label: 'Release Date',
        format: (value: string) => new Date(value).toLocaleDateString(),
    },
    incompleteFields: {
        label: 'Incomplete Fields',
        format: (value: any[]) => {
            const count = value.length;
            const color = count > 0 ? 'danger' : 'success';
            return `<span class="badge bg-${color}">${value.length}</span>`;
        },
    },
    filesToDownload: {
        label: 'Undownloaded Files',
        format: (value: any[]) => {
            const count = value.length;
            const color = count > 0 ? 'danger' : 'success';
            return `<span class="badge bg-${color}">${value.length}</span>`;
        },
    },
};

const recordingColumns = {
    dueDate: {
        label: 'Due Date',
        format: (value: string) => new Date(value).toLocaleDateString(),
    },
    purpose: {
        label: 'Purpose'
    },
    incompleteFields: {
        label: 'Incomplete Fields',
        format: (value: any[]) => {
            const count = value.length;
            const color = count > 0 ? 'danger' : 'success';
            return `<span class="badge bg-${color}">${value.length}</span>`;
        },
    },
    filesToDownload: {
        label: 'Undownloaded Files',
        format: (value: any[]) => {
            const count = value.length;
            const color = count > 0 ? 'danger' : 'success';
            return `<span class="badge bg-${color}">${value.length}</span>`;
        },
    },
};

const navigateToItem = (item: any) => {
    if ((item as Episode).episodeNumber !== undefined) {
        router.push(`/episodes/${item.id}`);
    } else if ((item as Recording).dueDate !== undefined) {
        router.push(`/recordings/${item.id}`);
    } else {
        router.push(`/${item.attachedToType}s/${item.attachedToId}`);
    }
};

// Computed properties for incomplete items
const incompleteEpisodes = computed(() => {
    if (!dataLoaded.value) return [];

    let incompleteItems: any[] = [];
    const items = episodes.value;
    const filesToDownloadAll = undownloadedFiles.value;
    items.forEach(item => {
        const incompleteFields = Object.entries(item).filter(([key, value]) =>
            value === '' || value === null || value === undefined || Array.isArray(value) && value.length === 0 || Object.keys(value).length === 0 && value.constructor === Object
        ).map(([key]) => key);
        const filesToDownload = filesToDownloadAll.filter(file => file.attachedToId === item.id && file.attachedToType === 'episode').map(file => file.name);
        if (incompleteFields.length > 0 || filesToDownload.length > 0) {
            incompleteItems.push({
                ...item,
                incompleteFields,
                filesToDownload
            });
        }
    });
    return incompleteItems.sort((a, b) => a.episodeNumber - b.episodeNumber);
});

const incompleteRecordings = computed(() => {
    if (!dataLoaded.value) return [];

    let incompleteItems: any[] = [];
    const items = recordings.value;
    const filesToDownloadAll = undownloadedFiles.value;
    items.forEach(item => {
        const incompleteFields = Object.entries(item).filter(([key, value]) =>
            value === '' || value === null || value === undefined || Array.isArray(value) && value.length === 0 || Object.keys(value).length === 0 && value.constructor === Object
        ).map(([key]) => key);
        const filesToDownload = filesToDownloadAll.filter(file => file.attachedToId === item.id && file.attachedToType === 'recording').map(file => file.name);
        if (incompleteFields.length > 0 || filesToDownload.length > 0) {
            incompleteItems.push({
                ...item,
                incompleteFields,
                filesToDownload
            });
        }
    });
    return incompleteItems.sort((a, b) => a.dueDate - b.dueDate);
});

const incompleteUploads = computed(() => {
    if (!dataLoaded.value) return [];

    const incompleteItems: any[] = [];

    const localStorageItems = Object.entries(localStorage)
        .filter(([key]) => key.startsWith('uploadInProgress:'));

    localStorageItems.forEach(([key, value]) => {
        try {
            const uploadData = JSON.parse(value) as UploadData;
            uploadData.attachedTo
            incompleteItems.push(uploadData);
        } catch (error) {
            console.error(`Failed to parse upload data for key ${key}:`, error);
        }
    });

    // Remove localStorage items that are no longer relevant (not in uploads)
    const usedIds = uploads.value.map(upload => `uploadInProgress:${upload.id}`);
    localStorageItems.forEach(([key]) => {
        if (!usedIds.includes(key)) {
            localStorage.removeItem(key);
        }
    });

    return incompleteItems;
});

// Methods
const updateItem = async (item: Episode | Recording | FileUpload, type: 'episodes' | 'recordings' | 'uploads') => {
    if (isEpisode(item)) {
        const updatedItem = await fetchEpisodeById(item.id as string) as Episode;
        episodes.value = episodes.value.map(episode =>
            episode.id === updatedItem.id ? updatedItem : episode
        );
    } else if (isRecording(item)) {
        const updatedItem = await fetchRecordingById(item.id as string) as Recording;
        recordings.value = recordings.value.map(recording =>
            recording.id === updatedItem.id ? updatedItem : recording
        );
    } else if (isUpload(item)) {
        uploads.value = uploads.value.map(upload =>
            upload.id === item.id ? item : upload
        );
    }
};

const reloadPage = () => {
    window.location.reload();
};

const refreshActivities = async () => {
    activities.value = await fetchActivities();
};

const getUndownloadedFiles = async () => {
    return await fetchUndownloadedFiles();
};

const loadItems = async () => {
    episodes.value = await fetchEpisodes();
    recordings.value = await fetchRecordings();
    uploads.value = await fetchIncompleteUploads();
    undownloadedFiles.value = await getUndownloadedFiles();
    activities.value = await fetchActivities();
    dataLoaded.value = true;
};

onMounted(loadItems);
</script>
