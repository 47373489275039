import { createApp } from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import 'quill/dist/quill.snow.css';
import { initializeMsal } from './services/authService';

// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@popperjs/core';

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

// Import BootstrapVue and icons plugin
import { BootstrapVueNext } from 'bootstrap-vue-next';


async function startApp() {
    try {
        await initializeMsal();

        const app = createApp(App);
        app.use(router);
        app.use(store);
        app.use(BootstrapVueNext);
        app.mount('#app');
    } catch (error) {
        console.error('Error starting the app', error);
    }
}

startApp();