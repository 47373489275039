<template>
    <div>
        <ItemList title="Episode List" :items="episodes" :columns="columns" @item-selected="navigateToEpisode" />
        <button class="btn btn-primary" @click="createNewEpisode">Add New Episode</button>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ItemList from '@/components/ItemList.vue';
import { fetchEpisodes } from '@/api/episodes'; // Assume this function fetches the episodes
import { Episode } from '@/models/episode';

const router = useRouter();
const route = useRoute();

const episodes = ref([] as Episode[]);
const columns = ref({
  episodeNumber: {
    label: '#',
  },
  audioTitle: {
    label: 'Title',
  },
  releaseDate: {
    label: 'Release Date',
    format: (value: string) => new Date(value).toLocaleDateString(),
  },
});

const loadEpisodes = async () => {
  episodes.value = await fetchEpisodes();
  episodes.value.sort((a, b) => (b.episodeNumber || 0) - (a.episodeNumber || 0));
};

const navigateToEpisode = (episode: { id: string }) => {
  router.push(`/episodes/${episode.id}`);
};

const createNewEpisode = () => {
  router.push('/episodes/new');
};

// Load episodes on component mount
onMounted(loadEpisodes);

// Watch route changes to reload episodes if the route changes
watch(() => route.path, loadEpisodes);
</script>

<style scoped>
/* Add any specific styles for the episode list view */
</style>
