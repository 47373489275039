import { createStore } from 'vuex';
import { getAccount, login, acquireToken, logout as appLogout, handleRedirectCallback, msalInstance } from '@/services/authService';
import router from '@/router';

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('authToken'),
    user: null,
    authToken: localStorage.getItem('authToken') || null,
  },
  mutations: {
    setAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setMsalAccount(state, user) {
      state.user = user;
      state.isAuthenticated = !!user; // If MSAL account exists, set isAuthenticated to true
    },
    setAuthToken(state, token) {
      state.authToken = token;
      if (token) {
        localStorage.setItem('authToken', token); // Store the token in localStorage
      } else {
        localStorage.removeItem('authToken');
      }
    },
  },
  actions: {
    async login({ commit }) {
      try {
        const loginResponse = await login(); // Trigger MSAL login process
        // console.log('Login Response:', loginResponse);
        const account = loginResponse.account;
        if (account) {
          commit('setMsalAccount', account);

          // Retrieve the access token
          const authToken = loginResponse.accessToken;
          commit('setAuthToken', authToken); // Set and store the authToken
          router.push('/'); // Redirect to home page
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    },
    logout({ commit }) {
      // Clear the app's local session
      commit('setAuthToken', null);
      commit('setMsalAccount', null);
      commit('setAuthenticated', false);
      localStorage.removeItem('authToken');

      // Clear the active account in MSAL
      msalInstance.setActiveAccount(null);

      router.push('/login');
    },
    async checkAuth({ commit }) {
      const token = localStorage.getItem('authToken');
      if (token) {
        commit('setAuthenticated', true);
        commit('setAuthToken', token);
      } else {
        commit('setAuthenticated', false);
        commit('setAuthToken', null);
      }

      // Check MSAL account
      const account = getAccount();
      if (account && token) {
        commit('setMsalAccount', account);
        msalInstance.setActiveAccount(account); // Set the active account

        const tokenRequest = {
          scopes: ['user.read'],
          account, // Pass the active account here
        };

        // Optionally, retrieve a new access token if needed
        try {
          const authToken = await acquireToken();
          commit('setAuthToken', authToken); // Set and store the new authToken
        } catch (error) {
          console.error('Error acquiring token:', error);
        }
      } else {
        commit('setMsalAccount', null);
        commit('setAuthToken', null);
        msalInstance.setActiveAccount(null); // Ensure no active account is set
      }
    },
    async handleAuthCallback({ commit }) {
      try {
        const response = await handleRedirectCallback();
        // console.log('Handle Redirect Response:', response); // Log the redirect response
        if (response) {
          const account = response.account;
          const authToken = response.accessToken;

          if (account && authToken) {
            msalInstance.setActiveAccount(account); // Set the active account
            commit('setMsalAccount', account);
            commit('setAuthToken', authToken); // Set and store the authToken
            commit('setAuthenticated', true);
          }
        }
      } catch (error) {
        console.error('Error handling auth callback:', error);
        commit('setAuthenticated', false);
      }
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    msalAccount: (state) => state.user,
    authToken: (state) => state.authToken,
  },
});