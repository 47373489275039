<template>
<div class="recording-template">
    <h1>{{ pageTitle }}</h1>

    <!-- Message Display -->
    <AlertMessage :messages="alertMessages" />
    <div v-if="isLoading">
        <p>Loading...</p>
    </div>
    <div v-else-if="error">
        <p>Error: {{ error }}</p>
    </div>
    <div v-else class='row justify-content-center'>
        <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- Recording Title -->
            <div class="form-group row">
                <div class="col-8 col-md-8 col-sm-12">

                    <div class="form-group row">
                        <label for="recordingTitle" class="col-sm-2 col-form-label">Title</label>
                        <div class="col-sm-10">
                            <input type="text" id="recordingTitle" v-model="recording.title" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-12">
                    <div class="form-group row">
                        <label for="dueDate" class="col-sm-2 col-form-label">Due</label>
                        <div class="col-sm-10">
                            <input type="date" id="dueDate" v-model="recording.dueDate" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Raw File Upload Section -->
            <div class="form-group row" v-if="recording.id">
                <label for="rawUpload" class="col-sm-2 col-form-label">Upload Raw Files</label>
                <div class="col-sm-10">
                    <input type="file" id="rawUpload" data-category="rawFiles" @change="handleFileUpload" class="form-control" multiple />
                    <div class="uploaded-files">
                        <FileEmbed v-for="file in recordingRawFiles" :key="file.id" :fileId="file.id" @changed="loadRecording" @delete="fileDeleted(file)" />
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="recordingNotes" class="col-sm-2 col-form-label">Recording Notes</label>
                <div class="col-sm-10">
                    <textarea id="recordingNotes" v-model="recording.notes" class="form-control" />
                    </div>
        </div>
    
        <!-- Recording Purpose -->
        <div class="form-group row">
            <label for="recordingPurpose" class="col-sm-2 col-form-label">Recording Purpose</label>
            <div class="col-sm-10">
                <input type="text" id="recordingPurpose" v-model="recording.purpose" class="form-control" />
            </div>
        </div>
    
        <!-- Final File Upload Section -->
        <div class="form-group row" v-if="recording.id">
            <label for="finalFiles" class="col-sm-2 col-form-label">{{ recordingFinalFiles.length > 0 ? '' : 'Upload ' }} Final Files</label>
            <div class="col-sm-10">
                <input v-if="recordingFinalFiles.length === 0" type="file" id="finalFiles" data-category="finalFiles" @change="handleFileUpload" class="form-control" multiple />
                <div class="uploaded-files">
                    <FileEmbed
                        v-for="file in recordingFinalFiles"
                        :key="file.id"
                        :fileId="file.id"
                        @delete="fileDeleted(file)"
                    />
                </div>
            </div>
        </div>
    
        <div class="transcript-container row form-group" v-if="recording.id">
            <label class="col-sm-2 col-form-label">Transcript</label>
            <div class="transcript-content col-sm-10">
                {{ recording.transcript }}
            </div>
        </div>

        <AttachmentsEmbed
            v-if="recording.id"
            thingType="recording"
            :thingId="recording.id"
        />
    
        <!-- Save Button -->
        <div class="form-group row">
            <div class="col-sm-7 offset-sm-2">
                <button @click="saveRecording" class="btn btn-primary">{{ submitText }}</button>
            </div>
            <div class="col-sm-3">
                <button @click="deleteThisRecording" class="btn btn-sm btn-danger">Delete Recording</button>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12">
        <ActivityLog
        v-if="activities.length > 0"
        :logs="activities"
        type="item"
        @update="refreshActivities"
        />
    </div>
</div>
        Created: {{ created }} | Updated: {{ updated }}
    </div>
    
</template>

<script setup lang="ts">
import {
    ref,
    onMounted,
    watch
} from 'vue';
import {
    useRouter,
    useRoute
} from 'vue-router';
import { Recording } from '@/models/recording';
import {
    uploadFileWithProgress
} from '@/utils/uploadUtils';
import {
    formatLocalDate
} from '@/utils/normalize';
import AlertMessage from '@/components/AlertMessage.vue';
import FileEmbed from '@/components/FileEmbed.vue';
import ActivityLog from '../components/ActivityLog.vue';
import AttachmentsEmbed from '../components/AttachmentsEmbed.vue';
import { FileUpload } from '@/models/file';
import { AlertMessageData } from '@/models/alertMessageData';
import { ActivityLog as ActivityLogObject } from '@/models/activityLog';
import { fetchRecordingById, fetchRecordings, getFilesForRecording, deleteRecording, createOrUpdateRecording } from '@/api/recordings';
import { fetchActivities } from '@/api/activityLog';
import { useAsyncState } from '@vueuse/core';
import { computed } from 'vue';

const recordingRawFiles = ref < FileUpload[] > ([]);
const recordingFinalFiles = ref < FileUpload[] > ([]);
const pageTitle = ref('');
const submitText = ref('');
const alertMessages = ref < AlertMessageData[] > ([]);
const created = ref(formatLocalDate(new Date()));
const updated = ref(formatLocalDate(new Date()));
const activities = ref < ActivityLogObject[] > ([]);

const router = useRouter();
const route = useRoute();

function setPageTitle() {
    const recordingId = route.params.id as string;
    if (recordingId) {
        const recordingTitle = recording.value.title || 'Recording';
        pageTitle.value = `Edit ${recordingTitle}`;
        submitText.value = 'Update Recording';
    } else {
        pageTitle.value = 'Create Recording';
        submitText.value = 'Add Recording';
    }
}

const { state: recording, isLoading, isReady, error, execute: loadRecording } = useAsyncState(async () => {
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    let r = {
            id: undefined,
            title: '',
            purpose: '',
            transcript: 'No transcript available.',
            notes: '',
            driveDirectoryString: '',
            created: new Date(),
            updated: new Date(),
            dueDate: new Date(),
        } as Recording;
    if (route.params.id) {
        const recordingId = route.params.id as string;
        r = await fetchRecordingById(recordingId);
        const recordingFiles = await getFilesForRecording(recordingId);
        recordingRawFiles.value = recordingFiles.filter((file: FileUpload) => file.category === 'rawFiles');
        recordingFinalFiles.value = recordingFiles.filter((file: FileUpload) => file.category === 'finalFiles');
        setPageTitle();
        activities.value = await fetchActivities('recording', recordingId);
    } 
    created.value = formatLocalDate(r.created);
    updated.value = formatLocalDate(r.updated);
    return r
    }, {} as Recording, { immediate: true });

const status = computed(() => {
    if (isLoading.value) {
        return 'loading';
    } else if (error.value) {
        return 'error';
    } else if (isReady.value) {
        return 'ready';
    } else {
        return 'idle';
    }
})

async function refreshActivities() {
    activities.value = await fetchActivities('recording', recording.value.id);
}

function fileDeleted(file: FileUpload) {
    const fileMessage: AlertMessageData = {
        text: `File ${file.name} deleted successfully.`,
        type: 'success',
    };
    alertMessages.value.push(fileMessage);
    loadRecording();
}

async function deleteThisRecording() {
    if (recording.value.id) {
        if (confirm('Are you sure you want to delete this recording?')) {
            await deleteRecording(recording.value.id);
            router.push('/recordings');
        }
    }
}

async function handleFileUpload(event: any) {
    const files = event.target.files;
    const category = event.target.dataset.category;

    if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileMessage: AlertMessageData = {
                text: `Uploading ${file.name}`,
                type: 'progress',
                progress: 0,
            };
            alertMessages.value.push(fileMessage);

            const formData = new FormData();
            formData.append('file', file);
            formData.append('attachedToId', recording.value.id?.toString() || '');
            formData.append('attachedToType', 'recording');
            formData.append('category', category);
            formData.append('name', file.name);

            try {
                await uploadFileWithProgress(
                    `https://${process.env.VUE_APP_AWS_STORAGE_BUCKET_NAME}.s3.us-east-1.amazonaws.com/uploads/recording/${recording.value.id}/${file.name}`,
                    formData,
                    (progress, message) => {
                        fileMessage.progress = progress;
                        fileMessage.text = message || `Uploading ${file.name}`;
                        alertMessages.value = [...alertMessages.value];
                    },
                    (response) => {
                        fileMessage.type = 'success';
                        fileMessage.text = `File ${file.name} uploaded successfully.`;
                        alertMessages.value = [...alertMessages.value];
                        loadRecording();
                    },
                    (error) => {
                        fileMessage.type = 'error';
                        fileMessage.text = `Error uploading file ${file.name}: ${error}`;
                        alertMessages.value = [...alertMessages.value];
                    }
                );
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    }
}

async function saveRecording(event: any) {
    const button = event.target as HTMLButtonElement;
    button.disabled = true;
    button.textContent = 'Saving...';
    try {
        recording.value = await createOrUpdateRecording(recording.value);

        const fileMessage: AlertMessageData = {
            text: `${recording.value.title || 'Recording'} saved successfully.`,
            type: 'success',
        };
        alertMessages.value.push(fileMessage);
        button.textContent = 'Saved!';

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        setTimeout(() => {
            button.textContent = submitText.value;
            button.disabled = false;
            router.push('/recordings/' + recording.value.id);
        }, 2000);
    } catch (error) {
        const fileMessage: AlertMessageData = {
            text: `Recording ${recording.value.id} was not saved.`,
            type: 'error',
        };
        alertMessages.value.push(fileMessage);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
}

onMounted(() => {
    setPageTitle();
});

watch(() => route.params.id, (id, oldId) => {
    if (id !== oldId) {
        loadRecording();
    }
});
</script>

<style scoped>
h1,
h2 {
    margin-bottom: 30px;
}

.recording-template {
    max-width: 95%;
    margin: 0 auto;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.quill-editor {
    height: 300px;
}

.transcript-container {
    margin-top: 20px;
}

.transcript-content {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    white-space: pre-wrap;
}

.new-file {
    font-weight: bold;
    color: red;
}
</style>
