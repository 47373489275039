<template>
  <div v-if="messages.length" class="alert-container">
    <div
      v-for="(message, index) in filteredMessages"
      :key="index"
      :class="['alert', alertTypeClass(message)]"
    >
      <span v-if="message.type === 'progress'">{{ message.text }} ({{ message.progress }}%)</span>
      <span v-else>
        <span v-html="message.text"></span>
        <a v-if="message.retryObject" href="#" @click.prevent="retryUpload(message.retryObject)">Retry</a>
      </span>
      <button class="btn btn-close float-end" @click="dismiss(message)"></button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AlertMessageData } from '../models/alertMessageData';
import { uploadFileWithProgress } from '../utils/uploadUtils';

// Props
const props = defineProps<{
  messages: AlertMessageData[];
}>();

// Reactive properties and methods
const filteredMessages = computed(() => props.messages.filter(m => !m.hidden));

const alertTypeClass = (message: AlertMessageData) => {
  switch (message.type) {
    case 'success':
      setTimeout(() => {
        message.hidden = true;
      }, 5000);
      return 'alert-success';

    case 'error':
      return 'alert-danger';
    case 'info':
      return 'alert-info';
    case 'progress':
      return 'alert-warning';
    default:
      return '';
  }
};

const dismiss = (message: AlertMessageData) => {
  message.hidden = true;
};

const retryUpload = async (uploadObject: any) => {
  try {
    await uploadFileWithRetry(uploadObject);
  } catch (error) {
    console.error('Error retrying file upload:', error);
  }
};

const uploadFileWithRetry = async (uploadObject: any) => {
  try {
    await uploadFileWithProgress(
      uploadObject.url,
      uploadObject.formData,
      uploadObject.onProgress,
      uploadObject.onSuccess,
      uploadObject.onError
    );
  } catch (error) {
    console.error('Error retrying file upload:', error);
  }
};
</script>

<style scoped>
.alert-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.alert {
  padding: 10px;
  border-radius: 5px;
}
.alert-success {
  background-color: #d4edda;
  color: #155724;
}
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}
.alert-info {
  background-color: #cce5ff;
  color: #004085;
}
.alert-warning {
  background-color: #fff3cd;
  color: #856404;
}
.alert-hidden {
  display: none;
}
</style>