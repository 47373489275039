export function normalizeItem<T, K extends keyof T>(
  item: T,
  normalizationMap: Record<K, string>,
  additionalValues: Partial<Record<string, any>> = {}
): Record<string, any> {
  const normalizedItem: Record<string, any> = { ...additionalValues };
  const incompleteFields: string[] = [];

  for (const key in normalizationMap) {
    if (normalizationMap.hasOwnProperty(key)) {
      const normalizedKey = normalizationMap[key];
      const value = item[key as keyof T];

      // Only check for empty string if the value is a string
      if (typeof value === 'string' && value === '') {
        normalizedItem[normalizedKey] = '';
        incompleteFields.push(normalizedKey);
      } else if (value === null || value === undefined) {
        normalizedItem[normalizedKey] = '';
        incompleteFields.push(normalizedKey);
      } else {
        normalizedItem[normalizedKey] = value;
      }
    }
  }

  normalizedItem['incompleteItems'] = incompleteFields;
  return normalizedItem;
}

export function formatLocalDate(date: Date): string {
  const d = new Date(date)
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  }).format(d);
}