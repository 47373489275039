<template>
<div class="file-preview row">
    <!-- Message Display -->
    <AlertMessage :messages="alertMessages" />
    <div v-if="!uploadInProgress">
        <label for="videoInput" :class="['btn', selectedDirectory ? 'btn-secondary' : 'btn-primary']">
            {{ selectedDirectory ? 'Change Session Directory' : 'Choose Session Directory'}}
        </label>
        <input id="videoInput" type="file" ref="videoInput" @change="handleFiles" webkitdirectory directory accept="video/*" style="display: none;" />
        <span v-if="selectedDirectory">
            Selected Session Directory: {{ selectedDirectory }}
        </span>
    </div>
    <div class="col-8" v-if="!uploadInProgress">

        <div v-if="videos.length">
            <h2>Video Previews</h2>
            <div v-for="(video, index) in videos" :key="index" class="mb-4">
                <video :src="video.url" controls class="w-100 mb-2"></video>
                <div class="d-flex justify-content-between align-items-center mb-0">
                    <div class="col-sm-4">
                        <h3>{{ video.name }}</h3>
                    </div>

                    <div class="col-sm-8" v-if="!thingType || !thingId">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="type">File Type</label>
                            </div>
                            <select class="custom-select" id="type" v-model="video.type">
                                <option value="" selected>Choose one...</option>
                                <option value="episode">Episode</option>
                                <option value="recording">Other File</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-8" v-else>
                        <button class="btn btn-primary" @click="selectVideo(video)">Add to this {{ thingType }}</button>
                    </div>

                </div>
                <div class="d-flex justify-content-between align-items-center mb-0">

                    <div class="col-sm-2" v-if="video.type === 'episode'">
                        <input type="number" class="form-control " placeholder="#" v-model="video.episodeNumber" @change="checkEpisodeNumber" />
                    </div>
                    <div class="col-sm-6" v-if="video.type">
                        <input type="text" class="form-control " placeholder="Title" v-model="video.title" />
                    </div>
                    <button :class="['btn', video.selected ? 'btn-danger' : 'btn-primary']" @click="selectVideo(video)" v-if="video.type === 'episode' || video.type === 'recording'">
                        {{ video.selected ? 'Cancel Upload' : 'Include in Upload' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div v-if="selectedVideos.length && !uploadInProgress">
            <h2>Selected Videos</h2>
            <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(video, index) in selectedVideos" :key="index">
                    <h4>{{ video.type === 'episode' ? 'Episode ' + video.episodeNumber : '' }}{{ video.type === 'episode' && video.title ? ': ' + video.title : video.title}} - <button class="btn btn-sm btn-danger" @click="selectVideo(video)">Remove</button></h4>
                    <div class="form-group row">
                        <label for="releaseDate" class="col-sm-4 col-form-label" v-if="video.type === 'episode'">Release Date:</label>
                        <div class="col-sm-6" v-if="video.type === 'episode'">
                            <input type="date" class="form-control mb-2" id="releaseDate" v-model="video.releaseDate" placeholder="Release Date" />
                        </div>
                    </div>
                    <input type="text" class="form-control mb-2" v-model="video.videoTitle" placeholder="Video Title" v-if="video.type === 'episode'" />
                    <input type="text" class="form-control mb-2" v-model="video.audioTitle" placeholder="Audio Title" v-if="video.type === 'episode'" />
                    <textarea class="form-control mb-1" rows="3" v-model="video.description" placeholder="Description" v-if="video.type === 'episode'"></textarea>
                    
                    <textarea class="form-control mb-1" rows="3" v-model="video.thumbnailIdeas" placeholder="Thumbnail Ideas" v-if="video.type === 'episode'"></textarea>
                    <textarea class="form-control mb-1" rows="3" v-model="video.purpose" placeholder="What will this video be used for?" v-if="video.type === 'recording'"></textarea>

                    <textarea class="form-control mb-1" rows="3" v-model="video.notes" placeholder="Other notes"></textarea>
                </li>
            </ul>
        </div>

        <button class="btn btn-primary" @click="uploadSelectedVideos" v-if="selectedVideos.length && !uploadInProgress">Upload Selected Videos</button>
    </div>
</div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
    createEpisode, fetchEpisodeByEpisodeNumber, fetchEpisodeById
} from '@/api/episodes'; // Assume this function creates a new episode
import {
    createRecording, fetchRecordingById
} from '@/api/recordings'; // Assume this function creates a new recording
import AlertMessage from '@/components/AlertMessage.vue'; // Import the AlertMessage component
import { AlertMessageData } from '../models/alertMessageData';
import { Episode } from '../models/episode';
import { Recording } from '../models/recording';
import { uploadFileWithProgress } from '../utils/uploadUtils';
interface Video {
    name: string;
    url: string;
    file: any;
    type?: string;
    episodeNumber?: number;
    title?: string;
    videoTitle?: string;
    audioTitle?: string;
    description?: string;
    purpose?: string;
    thumbnailIdeas?: string;
    notes?: string;
    releaseDate?: Date;
    selected?: boolean;
}

const videos = ref([] as Video[]); // Store videos for preview
const selectedVideos = ref([] as Video[]); // Store selected videos for upload
const uploadInProgress = ref(false);
const alertMessages = ref([] as AlertMessageData[]);
const allFiles = ref([] as any);
const selectedDirectory = ref('');
const props = defineProps<{
    thingType?: string;
    thingId?: string;
    incompleteUploads?: Array<any>;
}>();

const handleFiles = (event: any) => {
    const files = Array.from(event.target.files) as any[];
    const firstFile = files[0] as any;
    allFiles.value = files;

    const parseFileName = (fileName: string) => {
        const parts = fileName.split(' ');
        if (parts.length === 1) return 'Take 1';
        const takeNumber = parseInt(parts[1])
        return `Take ${takeNumber}`;
    };

    if (files.length === 0) return;

    // Extract the parent directory name
    const parentDir = firstFile.webkitRelativePath.split('/')[0];
    selectedDirectory.value = parentDir;

    // Filter files to include only those directly in the parent directory and that are video files
    const filteredFiles = files.filter(file => {
        const relativePathParts = file.webkitRelativePath.split('/');
        return relativePathParts.length === 2 && file.type.startsWith('video/') && !file.name.includes('._');
    });
    console.log('Filtered Files:', filteredFiles);

    if (filteredFiles.length === 0) {
        selectedDirectory.value = ''; // Clear the directory name if no videos are found
        alert('No video files found in the selected directory.');
    } else {
        // Store the filtered video files for preview
        videos.value = filteredFiles.map(file=> ({
            name: parseFileName(file.name),
            url: URL.createObjectURL(file),
            file
        }));
    }
}

const checkEpisodeNumber = async (event: any) => {
    const episodeNumber = parseInt(event.target.value);
    try {
        await fetchEpisodeByEpisodeNumber(episodeNumber);
        alert('An episode with this number already exists. Please enter a different number.');
        event.target.value = '';
    } catch (error) {
        // No episode with this number exists, which is what we want
    }
};


const selectVideo = async (video: Video) => {
    if (props.thingType && props.thingId) {
                const response = confirm(`Upload files to this ${props.thingType}?`);
                video.type = props.thingType;
                if (props.thingType === 'episode') {
                    const thing = await fetchEpisodeById(props.thingId) as Episode;
                    video.episodeNumber = thing.episodeNumber;
                    video.videoTitle = thing.youtubeTitle;
                    video.audioTitle = thing.audioTitle;
                    video.description = thing.audioDescription;
                    video.releaseDate = thing.releaseDate;
                    video.thumbnailIdeas = thing.thumbnailIdeas;
                    video.notes = thing.notes;
                } else if (props.thingType === 'recording') {
                    const thing = await fetchRecordingById(props.thingId) as Recording;
                    video.title = thing.title;
                    video.purpose = thing.purpose;
                    video.notes = thing.notes;
                }
                if (!response) return;
                video.selected = true;
                console.log('Selected Video:', video);
                selectedVideos.value = [video];
                // this.selectedVideos.push(video);
                return await uploadSelectedVideos();
                
            }
    if (!video.type) {
        alert('Please select a file type for the video.');
        return;
    }
    if (video.type === 'episode' && !video.episodeNumber) {
        alert('Please enter an episode number for the video.');
        return;
    }
    if (video.type === 'recording' && !video.title) {
        alert('Please enter a title for the video.');
        return;
    }
    if (video.selected) {
        video.selected = false;
        video.type = '';
        selectedVideos.value = selectedVideos.value.filter(selectedVideo => selectedVideo !== video);
        return;
    }
    video.selected = true;
    selectedVideos.value.push(video);
};

const uploadFileWithRetry = async (uploadObject: any) => {
    await uploadFileWithProgress(
        uploadObject.url,
        uploadObject.formData,
        uploadObject.onProgress,
        uploadObject.onSuccess,
        uploadObject.onError
    );
};

const retryUpload = (uploadObject: string) => {
    uploadObject = JSON.parse(uploadObject);
    uploadFileWithRetry(uploadObject);
};

const uploadSelectedVideos = async () => {
    if (uploadInProgress.value) {
        alert('An upload is already in progress. Please wait for it to complete.');
        return;
    }
    uploadInProgress.value = true;
    let filesToBeUploaded = []
    try {
        for (const video of selectedVideos.value) {
            if (video.type === 'episode') {
                if (!video.episodeNumber && !props.thingId) {
                    alert('Please enter an episode number for the video.');
                    return;
                }
                let episode: Episode;
                if (!props.thingId) {
                    const episodeFormData: Partial<Episode> = {
                    episodeNumber: video.episodeNumber,
                    audioTitle: video.audioTitle?.toString() || '',
                    youtubeTitle: video.videoTitle?.toString() || '',
                    audioDescription: video.description?.toString() || '',
                    releaseDate: new Date(video.releaseDate?.toString() || ''),
                    driveDirectoryString: selectedDirectory.value,
                    thumbnailIdeas: video.thumbnailIdeas?.toString() || '',
                    notes: video.notes?.toString() || '',
                    };
                    episode = await createEpisode(episodeFormData);
                    if (!episode.id) {
                        console.error('Error creating episode:', episode);
                        continue;
                    }
                    alertMessages.value.push({
                        type: 'success',
                        text: `Episode ${episode.episodeNumber} created successfully.`
                 });
                } else {
                    episode = await fetchEpisodeById(props.thingId) as Episode;
                    if (!episode.id) {
                        console.error('Error fetching episode:', episode);
                        continue;
                    }
                }
                

                const mainFileRegex = /^([A-Za-z0-9\s-]+) (\d{2})?\.mp4$/;
                const mainFileMatch = video.file.name.match(mainFileRegex);
                console.log(mainFileMatch)
                console.log(video)
                if (!mainFileMatch) {
                    console.error('Invalid main file name:', video.name);
                    continue;
                }

                const baseName = mainFileMatch[1].trim(); // The main prefix of the session
                const takeNumber = mainFileMatch[2] ? mainFileMatch[2].trim() : null; // The optional take number
                const episodeNumber = video.episodeNumber
                console.log(`^${baseName} CAM \\d+${takeNumber ? ` ${takeNumber}` : ''}\\.mp4$`)
                const videoIsoRegex = new RegExp(`^${baseName} CAM \\d+${takeNumber ? ` ${takeNumber}` : ''}\\.mp4$`);
                const audioIsoRegex = new RegExp(`^${baseName} (MIC \\d+|CAM \\d+)${takeNumber ? ` ${takeNumber}` : ''}\\.wav$`);

                // const uploadPromises: Promise<void>[] = [];
                // let filesToBeUploaded = []
                for (const file of allFiles.value) {
                    if (file.name.includes('._')) {
                        console.log('Skipping File Because _ :', file.name);
                        continue;
                    }

                    if (videoIsoRegex.test(file.name) || audioIsoRegex.test(file.name)) {
                        const originalFileName = file.name;
                        console.log('Uploading File:', file);
                        // const uploadPromise = (async () => {
                        try {
                            const fileTypeMatch = file.name.match(/(CAM \d+|MIC \d+)/);
                            const fileType = fileTypeMatch ? fileTypeMatch[0] : 'Unknown';
                            console.log('File Type:', fileType);

                            const fileMessage: AlertMessageData = {
                                type: 'progress',
                                text: `Uploading File: ${file.name}`,
                                progress: 0
                            };
                            alertMessages.value.push(fileMessage);
                            const formData = new FormData();
                            formData.append('file', file);
                            formData.append('attachedToType', 'episode');
                            formData.append('attachedToId', episode.id.toString());
                            formData.append('name', `Episode ${episodeNumber} - ${fileType}`);
                            formData.append('category', 'rawFiles');
                            formData.append('originalFileName', originalFileName);
                            formData.forEach((value, key) => {
                                console.log(key, value);
                            });
                            const url = `https://dbb-podcast-files.s3.us-east-1.amazonaws.com/uploads/episode/${episode.id}/Episode ${episodeNumber} - ${fileType}.${originalFileName.split('.').pop()}`;
                            filesToBeUploaded.push({url, formData, fileMessage})
                        } catch (error) {
                            console.error('Error adding file:', error);
                        }
                    } else {
                        console.log('Skipping File Based on Regex:', file.name);
                    }
                }
        
            } else if (video.type === 'recording') {
                console.log('Uploading Recording:', video);
                const file = video.file
                if (!file) {
                    console.error('No file found for recording:', video);
                    continue;
                }
                try {
                    const recordingFormData: Partial<Recording> = {
                        title: video.title,
                        purpose: video.purpose,
                        driveDirectoryString: selectedDirectory.value,
                        notes: video.notes,
                    };
                    
                    const newRecording = await createRecording(recordingFormData);
                    console.log('New Recording:', newRecording);
                    if (!newRecording.id) {
                        console.error('Error creating recording:', newRecording);
                        continue;
                    }
                    alertMessages.value.push({
                        type: 'success',
                        text: `Recording ${newRecording.id} created successfully.`
                    });
                    const fileMessage: AlertMessageData = {
                                type: 'progress',
                                text: `Uploading File: ${video.name}`,
                                progress: 0
                            };
                    alertMessages.value.push(fileMessage);
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('attachedToType', 'recording');
                    formData.append('attachedToId', newRecording.id.toString());
                    formData.append('name', video.title || 'Recording ' + newRecording.id?.toString());
                    formData.append('category', 'rawFiles');
                    const url = `https://dbb-podcast-files.s3.us-east-1.amazonaws.com/uploads/recording/${newRecording.id}/${video.title || 'Recording ' + newRecording.id?.toString()}.${file.originalFileName.split('.').pop()}`;
                    filesToBeUploaded.push({url, formData, fileMessage})
                } catch (error) {
                    console.error('Error uploading recording:', error);
                }
            }
            
        }
        const promises = [];
        for (const {url, formData, fileMessage} of filesToBeUploaded) {

            try {
                const uploadObject = {
                    url,
                    formData,
                    onProgress: (progress: number, message?: string) => {
                        fileMessage.progress = progress;
                        fileMessage.text = message || `Uploading ${formData.get('name')}`;
                        alertMessages.value = [...alertMessages.value]
                    },
                    onSuccess: (response: any) => {
                        fileMessage.type = 'success';
                        fileMessage.text = `File ${formData.get('name')} uploaded successfully.`;
                        alertMessages.value = [...alertMessages.value]
                    },
                    onError: (error: any) => {
                        fileMessage.type = 'error';
                        fileMessage.text = `Error uploading file ${formData.get('name')}: ${error}`;
                        fileMessage.retryObject = uploadObject; // Store the object for retry
                        alertMessages.value = [...alertMessages.value]
                    }
                };
                const fileName = formData.get('name') as string;
                console.log('Uploading File:', fileName);
                promises.push(uploadFileWithRetry(uploadObject));
            } catch (error) {
                console.error('Error uploading file:', error);
            }
            // } catch (error) {
            //     console.error('Error uploading file:', error);
        }

        await Promise.all(promises);
    } catch (error) {
        console.error('Error uploading videos:', error);
    } finally {
        const newMessage: AlertMessageData = {
            type: 'success',
            text: 'All videos uploaded successfully. You can navigate away from this page now.'
        };
        if (alertMessages.value.filter(message => message.type === 'error').length === 0) {
            alertMessages.value.push(newMessage);
        }
    }
}
</script>

<style scoped>
/* Add any specific styles for your video preview interface */
h2 {
    margin-top: 20px;
    text-align: left
}

.file-preview {
    max-width: 80%;
    margin: 0 auto;
}
</style>
