import { FileUpload } from '@/models/file';
import { S3Client, S3ClientConfig, CreateMultipartUploadCommand, UploadPartCommand, CompleteMultipartUploadCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { webSocketService } from '../utils/websocketService';

import axios from 'axios';
import { create } from 'domain';
import { on } from 'events';
import { get } from 'http';

const apiUrl = process.env.VUE_APP_API_URL;

const AWS_REGION = process.env.VUE_APP_AWS_REGION || 'us-east-1'; // Default to 'us-east-1' if not set
const AWS_ACCESS_KEY_ID = process.env.VUE_APP_AWS_ACCESS_KEY_ID!;
const AWS_SECRET_ACCESS_KEY = process.env.VUE_APP_AWS_SECRET_ACCESS_KEY!;
const AWS_STORAGE_BUCKET_NAME = process.env.VUE_APP_AWS_STORAGE_BUCKET_NAME!;

if (!AWS_STORAGE_BUCKET_NAME) {
    throw new Error("S3 Bucket name is missing.");
}

export const fetchFiles = async (): Promise<FileUpload[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/files`);
        return response.data;
    } catch (error) {
        console.error('Error fetching files:', error);
        throw error;
    }
}

export async function fetchFileById(id: string): Promise<FileUpload> {
    if (!id || id === 'undefined') {
        throw new Error('No ID provided');
    }
    try {
        const response = await axios.get(`${apiUrl}/api/files/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching file:', error);
        throw error;
    }
}

async function createFileObject(formData: FormData): Promise<FileUpload> {
    try {
        const response = await axios.post(`${apiUrl}/api/files`, formData);
        return response.data;
    } catch (error) {
        console.error('Error creating file:', error);
        throw error;
    }
}

// Define the types for the function parameters
interface UploadFileParams {
    formData: FormData;
    onProgress: (progress: number, message: string) => void;
}

const createS3Client = (): S3Client => {
    const config: S3ClientConfig = {
        region: AWS_REGION,
        credentials: {
            accessKeyId: AWS_ACCESS_KEY_ID,
            secretAccessKey: AWS_SECRET_ACCESS_KEY,
        },
    };
    return new S3Client(config);
};

interface UploadedPart {
    PartNumber: number;
    ETag: string; // The ETag returned by S3 after uploading the part
}

export interface UploadData {
    uploadId: string;
    parts: UploadedPart[];  // Customize the type to match your parts structure
    filePath: string;
    fileName: string;
    currentChunkIndex: number;
    totalChunks: number;
    percentProgress: number;
    [key: string]: any;  // Additional properties if needed
    attachedToType: string;
    attachedToId: string;
}

function saveUploadProgress(uploadData: UploadData): void {
    localStorage.setItem(`uploadInProgress:${uploadData.uploadId}`, JSON.stringify(uploadData));
}// The modified createFile function

export async function checkForIncompleteUploads(id?: string): Promise<UploadData[]> {
    const uploads: UploadData[] = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith('uploadInProgress:') && (!id || key.includes(id))) {
            const uploadData = localStorage.getItem(key);
            if (uploadData) {
                uploads.push(JSON.parse(uploadData) as UploadData);
            }
        }
    }
    const unfinishedUploads = await fetchIncompleteUploads();
    console.log('Unfinished uploads:', unfinishedUploads);
    unfinishedUploads.forEach((upload) => {
        const existingUpload = uploads.find((u) => u.uploadId === upload.id);
        if (!existingUpload) {
            uploads.push({
                uploadId: upload.id,
                parts: [] as UploadedPart[],
                filePath: upload.url,
                fileName: upload.name,
                currentChunkIndex: 0,
                totalChunks: 100,
                percentProgress: 0,
                attachedToType: upload.attachedToType as string,
                attachedToId: upload.attachedToId as string,
            });
        }
    });
    return uploads;
}

export async function resumeUpload(uploadData: UploadData, file: File, onProgress: (progress: number) => void, onSuccess: () => void, onError: (error: any) => void): Promise<void> {
    const s3Client = createS3Client();
    const partSize = 15 * 1024 * 1024;  // 15 MB per part
    uploadData.parts.sort((a, b) => a.PartNumber - b.PartNumber);
    try {
        // Start or resume multipart upload
        const multipartUpload = await s3Client.send(new CreateMultipartUploadCommand({
            Bucket: AWS_STORAGE_BUCKET_NAME,
            Key: uploadData.filePath,
            ContentType: file.type,
        }));

        for (let i = uploadData.currentChunkIndex; i < uploadData.totalChunks; i++) {
            const start = i * partSize;
            const end = Math.min(start + partSize, file.size);
            const part = file.slice(start, end);

            const uploadPartCommand = new UploadPartCommand({
                Bucket: AWS_STORAGE_BUCKET_NAME,
                Key: uploadData.filePath,
                UploadId: multipartUpload.UploadId!,
                PartNumber: i + 1,
                Body: part,
            });

            const uploadPartResponse = await s3Client.send(uploadPartCommand);

            // Create a new part object
            const newPart = {
                ETag: uploadPartResponse.ETag as string,
                PartNumber: i + 1,
            };

            // Insert the new part in the correct order in the parts array
            const insertIndex = uploadData.parts.findIndex(part => part.PartNumber > newPart.PartNumber);
            if (insertIndex === -1) {
                uploadData.parts.push(newPart); // If no larger PartNumber is found, add at the end
            } else {
                uploadData.parts.splice(insertIndex, 0, newPart); // Insert at the correct position
            }

            uploadData.currentChunkIndex = i + 1;
            saveUploadProgress(uploadData);  // Update progress in localStorage

            const percentComplete = Math.round((uploadData.currentChunkIndex / uploadData.totalChunks) * 100);
            onProgress(percentComplete);  // Update progress callback

            webSocketService.sendMessage({
                path: uploadData.filePath,
                progress: percentComplete,
                type: 'progress',
            });
        }

        // Complete the multipart upload
        const completeUploadCommand = new CompleteMultipartUploadCommand({
            Bucket: AWS_STORAGE_BUCKET_NAME,
            Key: uploadData.filePath,
            UploadId: multipartUpload.UploadId!,
            MultipartUpload: {
                Parts: uploadData.parts,
            },
        });

        await s3Client.send(completeUploadCommand);

        console.log('Upload complete, marking as done in the backend.');
        const response = await axios.post(`${apiUrl}/api/files/${uploadData.uploadId}/upload-complete`);
        console.log('Upload complete response:', response.data);


        if (uploadData.fileName.includes('MIC 1')) {
            console.log('Triggering auphonic post-processing.');
            await axios.post(`${apiUrl}/api/files/${uploadData.uploadId}/auphonic`);
        }


        completeUpload(uploadData.uploadId);
        onSuccess();  // Trigger success callback

        webSocketService.sendMessage({
            path: uploadData.filePath,
            progress: 100,
            type: 'success',
        });

        console.log('Upload completed successfully');
    } catch (error) {
        console.error('Error resuming upload:', error);
        onError(error);  // Trigger error callback
    }
}

function completeUpload(uploadId: string): void {
    console.log('Completing upload:', uploadId);
    localStorage.removeItem(`uploadInProgress:${uploadId}`);
    // Additional completion logic...
}

export async function createFile(
    formData: FormData,
    onProgress: (progress: number, message: string) => void,
): Promise<any> {
    const file = formData.get('file') as File;
    if (!file) {
        throw new Error('File not found in formData');
    }

    const fileName = formData.get('name') as string;

    formData.delete('file'); // Remove the file from the formData object
    formData.append('size', file.size.toString()); // Add the file size to the formData object
    formData.append('mimetype', file.type); // Add the file mimetype to the formData object

    // Create the file object in your system
    const fileObj = await createFileObject(formData);
    const attachedToType = formData.get('attachedToType') as string;
    const attachedToId = formData.get('attachedToId') as string;

    // Setup S3 client
    const s3Client = createS3Client();
    const ext = fileName.includes('.') ? '' : `.${file.name.split('.').pop()}`;
    const filePath = `uploads/${attachedToType}/${attachedToId}/${fileName}${ext}`;
    console.log('ext:', ext, 'filePath:', filePath);

    // Initialize multipart upload using AWS SDK
    const upload = new Upload({
        client: s3Client,
        params: {
            Bucket: AWS_STORAGE_BUCKET_NAME,
            Key: filePath,
            Body: file,
            ContentType: file.type, // Assuming the file has the correct mimetype
        },
        partSize: 15 * 1024 * 1024, // 15 MB part size
        queueSize: 5, // Number of concurrent uploads
    });

    // Initialize upload data to track progress
    const savedUpload: UploadData = {
        uploadId: fileObj.id,
        parts: [],
        filePath,
        fileName,
        currentChunkIndex: 0,
        totalChunks: Math.ceil(file.size / (15 * 1024 * 1024)),
        percentProgress: 0,
        attachedToType,
        attachedToId,
    };

    // Save initial upload data in localStorage
    saveUploadProgress(savedUpload);

    // Progress tracking
    let lastLoaded = 0;
    upload.on("httpUploadProgress", (progress) => {
        // Calculate the chunk index based on progress.loaded
        const loaded = progress.loaded ?? 0;
        const currentChunkIndex = Math.floor(loaded / (15 * 1024 * 1024));

        if (loaded > lastLoaded) {
            savedUpload.currentChunkIndex = currentChunkIndex;
            savedUpload.percentProgress = Math.round((loaded / file.size) * 100);
            saveUploadProgress(savedUpload);  // Update progress in localStorage
            lastLoaded = loaded;
        }

        const percentComplete = Math.round((loaded / (progress.total ?? 1)) * 100);
        onProgress(percentComplete, `Uploading file: ${fileName} (${file.name})`);

        webSocketService.sendMessage({
            path: filePath,
            progress: percentComplete,
            type: 'progress',
        });
    });

    try {
        await upload.done();

        console.log('Upload complete, marking as done in the backend.');
        const response = await axios.post(`${apiUrl}/api/files/${fileObj.id}/upload-complete`);
        console.log('Upload complete response:', response.data);

        onProgress(100, 'File uploaded successfully');
        webSocketService.sendMessage({
            path: filePath,
            progress: 100,
            type: 'success',
        });

        if (fileName.includes('MIC 1')) {
            console.log('Triggering auphonic post-processing.');
            await axios.post(`${apiUrl}/api/files/${fileObj.id}/auphonic`);
        }

        completeUpload(savedUpload.uploadId);

        return { message: 'File uploaded successfully', fileObj };
    } catch (error) {
        console.log('Error uploading file:', error);
        webSocketService.sendMessage({
            path: filePath,
            progress: 0,
            type: 'error',
        });
        throw error;
    }
}

async function uploadFileInChunks(file: File, chunkSize: number = 1024 * 1024): Promise<string> {
    const totalChunks = Math.ceil(file.size / chunkSize);
    const uploadId = Date.now().toString(); // Unique ID for this upload

    let fileUrl = ''; // Variable to store the S3 URL

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkIndex', chunkIndex.toString());
        formData.append('totalChunks', totalChunks.toString());
        formData.append('uploadId', uploadId);
        formData.append('fileName', file.name);

        try {
            const response = await axios.post(`${apiUrl}/api/files/upload-chunk`, formData);
            if (chunkIndex + 1 === totalChunks && response.data.fileUrl) {
                fileUrl = response.data.fileUrl; // Get the S3 URL from the server's response
            }
            console.log(`Uploaded chunk ${chunkIndex + 1} of ${totalChunks}`);
        } catch (error) {
            console.error('Upload failed:', error);
            throw error;
        }
    }

    return fileUrl; // Return the S3 URL to be used when creating the file record
}

export const updateFile = async (id: string, fileData: Partial<FileUpload>): Promise<FileUpload> => {
    if (!id || id === 'undefined') {
        throw new Error('No ID provided');
    }
    try {
        const response = await axios.put(`${apiUrl}/api/files/${id}`, fileData);
        return response.data;
    } catch (error) {
        console.error('Error updating file:', error);
        throw error;
    }
}

export const listFiles = async (directoryPath: string): Promise<string[]> => {
    try {
        const response = await axios.post(`${apiUrl}/api/files/list-files`, { directoryPath });
        return response.data;
    } catch (error) {
        console.error('Error listing files:', error);
        throw error;
    }
}

export const deleteFile = async (id: string): Promise<void> => {
    if (!id || id === 'undefined') {
        throw new Error('No ID provided');
    }
    try {
        await axios.delete(`${apiUrl}/api/files/${id}`);
        await completeUpload(id);
    } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
    }
}

export const downloadFile = async (id: string): Promise<FileUpload> => {
    if (!id || id === 'undefined') {
        throw new Error('No ID provided');
    }
    try {
        // const file = await axios.get(`${apiUrl}/api/files/${id}`);
        // axios.get(`${file.data.url}`, { responseType: 'blob' });
        console.log('Downloading file:', id);
        return await markAsDownloaded(id);
        // return file.data;
    } catch (error) {
        console.error('Error downloading file:', error);
        throw error;
    }
}

export const markAsDownloaded = async (id: string): Promise<FileUpload> => {
    if (!id || id === 'undefined') {
        throw new Error('No ID provided');
    }
    try {
        console.log('Marking file as downloaded:', id);
        return await axios.put(`${apiUrl}/api/files/${id}/downloaded`);
    } catch (error) {
        console.error('Error marking file as downloaded:', error);
        throw error;
    }
}

export const undownloadFile = async (id: string): Promise<FileUpload> => {
    if (!id || id === 'undefined') {
        throw new Error('No ID provided');
    }
    try {
        console.log('Undownloading file:', id);
        return await axios.put(`${apiUrl}/api/files/${id}/undownloaded`);
    } catch (error) {
        console.error('Error undownloading file:', error);
        throw error;
    }
}

export const fetchUndownloadedFiles = async (): Promise<FileUpload[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/files/batch/undownloaded`);
        return response.data;
    } catch (error) {
        console.error('Error fetching undownloaded files:', error);
        throw error;
    }
}

export const fetchIncompleteUploads = async (): Promise<FileUpload[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/files/batch/incomplete`);
        return response.data;
    } catch (error) {
        console.error('Error fetching incomplete uploads:', error);
        throw error;
    }
}