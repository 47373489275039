import { Recording } from "@/models/recording";
import { FileUpload } from "@/models/file";

// src/api/recordings.ts

import axios from 'axios';
import { deleteFile } from "./files";

const apiUrl = process.env.VUE_APP_API_URL;

// Fetch all recordings
export const fetchRecordings = async (): Promise<Recording[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/recordings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching recordings:', error);
        throw error;
    }
};

// Fetch a single recording by ID
export const fetchRecordingById = async (id: string): Promise<Recording> => {
    if (!id || id === 'undefined') {
        throw new Error('Recording ID is required');
    }
    try {
        const response = await axios.get(`${apiUrl}/api/recordings/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching recording:', error);
        throw error;
    }
};

export const createOrUpdateRecording = async (recordingData: Partial<Recording>): Promise<Recording> => {
    const recordingId = recordingData.id || 0;
    console.log('Recording ID:', recordingId);
    if (recordingId) {
        try {
            await fetchRecordingById(recordingId);
            return await updateRecording(recordingId, recordingData);
        } catch (error) {
            console.error('Error fetching recording:', error);
        }
    }
    return await createRecording(recordingData);
}

// Create a new recording
export const createRecording = async (recordingData: Partial<Recording>): Promise<Recording> => {
    try {
        const response = await axios.post(`${apiUrl}/api/recordings`, recordingData);
        return response.data;
    } catch (error) {
        console.error('Error creating recording:', error);
        throw error;
    }
};

// Update an existing recording
export const updateRecording = async (id: string, recordingData: Partial<Recording>): Promise<Recording> => {
    try {
        const response = await axios.put(`${apiUrl}/api/recordings/${id}`, recordingData);
        return response.data;
    } catch (error) {
        console.error('Error updating recording:', error);
        throw error;
    }
};

// Delete an recording
export const deleteRecording = async (id: string): Promise<void> => {
    try {
        await axios.delete(`${apiUrl}/api/recordings/${id}`);
        const files = await getFilesForRecording(id);
        files.forEach(async file => {
            await deleteFile(file.id);
        });
    } catch (error) {
        console.error('Error deleting recording:', error);
        throw error;
    }
};

export const getFilesForRecording = async (recordingId: string): Promise<FileUpload[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/recordings/${recordingId}/files`);
        return response.data;
    } catch (error) {
        console.error('Error fetching files for recording:', error);
        throw error;
    }
}