<template>
<div>
    <ItemList title="Recordings List" :items="recordings" :columns="columns" @item-selected="navigateToRecording" />
    <button class="btn btn-primary" @click="createNewRecording">Add New Recording</button>
</div>
</template>

  
  
<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ItemList from '@/components/ItemList.vue';
import { fetchRecordings } from '@/api/recordings'; // Assume this function fetches the recordings
import { Recording } from '@/models/recording';

const router = useRouter();
const route = useRoute();

const recordings = ref([] as Recording[]);
const columns = ref({
  title: {
    label: 'Title',
  },
  purpose: {
    label: 'Purpose',
  },
  dueDate: {
    label: 'Due Date',
    format: (value: string) => new Date(value).toLocaleDateString(),
  },
  finalFiles: {
    label: 'Final Files',
    format: (values: string[]) => values
      ? values.map(value => `<iframe src="${value}"></iframe>`).join('<br>')
      : 'Missing',
  },
});

const loadRecordings = async () => {
  recordings.value = await fetchRecordings();
};

const navigateToRecording = (recording: { id: string }) => {
  router.push(`/recordings/${recording.id}`);
};

const createNewRecording = () => {
  router.push('/recordings/new');
};

// Load recordings on component mount
onMounted(loadRecordings);

// Watch route changes to reload recordings if the route changes
watch(() => route.path, loadRecordings);
</script>

  
<style scoped>
/* Add any specific styles for the episode list view */
</style>
