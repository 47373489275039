<template>
    <div class="activity-log">
        <h2>ActivityLog</h2>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th v-for="(column, key) in columns" :key="key" class="text-start">
                        {{ column.label || key }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in limitedActivityLog" @click="selectItem(item)" :key="item.id" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null" :class="{ 'hovered-row pointer-cursor': hoverIndex === index && type !== 'item'}">
                    <td v-for="(column, key) in columns" :key="key" class="text-start align-middle">
                        <span v-if="column.format">
                            <span v-html="column.format(item[key])"></span>
                        </span>
                        <span v-else>
                            <span v-html="item[key]"></span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-success" v-if="!showAll && logs.length > limit" @click="showAll = true">
      Show More
    </button>
    <button class="btn btn-warning" v-if="showAll" @click="showAll = false">
      Show Less
    </button>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, defineProps, defineEmits } from 'vue';
import { webSocketService } from '@/utils/websocketService';

interface LogItem {
  id: string;
  attachedToType?: string;
  attachedToId?: string;
  [key: string]: any;
}

const props = defineProps({
  logs: {
    type: Array as () => LogItem[],
    required: false,
    default: () => [],
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  limit: {
    type: Number,
    required: false,
    default: 25,
  },
});

const emit = defineEmits(['update']);

const columns = ref<Record<string, any>>({});
const showAll = ref(false);
const hoverIndex = ref<number | null>(null);

const limitedActivityLog = computed(() => {
  return showAll.value ? props.logs : props.logs.slice(0, props.limit);
});

const selectItem = (item: LogItem) => {
  if (!item.id) return;
  window.location.href = `/${item.attachedToType}s/${item.attachedToId}`;
};

const handleProgressUpdate = async (data: any) => {
  if (data.message === 'New activity log') {
    console.log('New activity log', data);
    await emit('update');
  }
};

const initializeColumns = () => {
  if (props.type === 'item') {
    columns.value = {
      timestamp: {
        label: 'Timestamp',
        format: (value: string) => new Date(value).toLocaleString(),
      },
      message: {
        label: 'Message',
      },
      details: {
        label: 'Changes',
        format: (value: any) =>
          Object.prototype.toString.call(value.changes) === '[object Object]'
            ? Object.entries(value.changes as Record<string, any>)
                .map(
                  ([key, { before, after }]) =>
                    `<strong>${key}:</strong> ${
                      before === '' ? 'empty' : before
                    } -> ${after === '' ? 'empty' : after}`
                )
                .join('<br>')
            : Object.prototype.toString.call(value.changes) === '[object String]'
            ? value.changes
            : 'No changes',
      },
    };
  } else if (props.type === 'list') {
    columns.value = {
      timestamp: {
        label: 'Timestamp',
        format: (value: string) => new Date(value).toLocaleString(),
      },
      message: {
        label: 'Message',
      },
      attachedToId: {
        label: 'ID',
      },
      details: {
        label: 'Changes',
        format: (value: any) =>
          Object.prototype.toString.call(value.changes) === '[object Object]'
            ? Object.entries(value.changes as Record<string, any>)
                .map(
                  ([key, { before, after }]) =>
                    `<strong>${key}:</strong> ${
                      before === '' ? 'empty' : before
                    } -> ${after === '' ? 'empty' : after}`
                )
                .join('<br>')
            : Object.prototype.toString.call(value.changes) === '[object String]'
            ? value.changes
            : 'No changes',
      },
    };
  } else {
    columns.value = {
      timestamp: {
        label: 'Timestamp',
        format: (value: string) => new Date(value).toLocaleString(),
      },
      message: {
        label: 'Message',
      },
      details: {
        label: 'Changes',
        format: (value: any) =>
          Object.prototype.toString.call(value.changes) === '[object Object]'
            ? Object.entries(value.changes as Record<string, any>)
                .map(
                  ([key, { before, after }]) =>
                    `<strong>${key}:</strong> ${
                      before === '' ? 'empty' : before
                    } -> ${after === '' ? 'empty' : after}`
                )
                .join('<br>')
            : Object.prototype.toString.call(value.changes) === '[object String]'
            ? value.changes
            : 'No changes',
      },
    };
  }
};

onMounted(() => {
  webSocketService.addListener(handleProgressUpdate);
  initializeColumns();
});

onUnmounted(() => {
  webSocketService.removeListener(handleProgressUpdate);
});
</script>

<style scoped>
  .activity-log {
    margin: 0 20px;
  }
  h2 {
    margin-top: 50px
}
.hovered-row {
  border: 2px solid #007bff; /* Change border on hover */
}
.pointer-cursor {
    cursor: pointer;
  }
  </style>