<template>
    <div class="incomplete-items-list">
        <h2>{{ title }}</h2>
        <div class="d-flex flex-wrap justify-content-center">
            <div class="card mb-3 mr-3 shadow" style="width: 18rem;" v-for="item in refmtItems" :key="item.id">
                <div class="card-header bg-primary text-white">
                    {{ item.cardTitle }}
                </div>
                <div class="card-body d-flex flex-column">
                    <div class="mb-2" v-for="key in Object.keys(columnHeaders)" :key="key" >
                        <strong>{{ columnHeaders[key].label }}: </strong> 
                        <span v-html="formatValue(item[key], key)"></span>
                    </div>
                </div>
                <div class="card-footer pointer-cursor bg-secondary text-white" @click="selectItem(item)">
                    Open {{ item.attachedToType || type.slice(0,-1) }}
                </div>
            </div>
        </div>
    </div>
</template>

  
  
<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { fetchRecordingById } from '@/api/recordings';
import { fetchEpisodeById } from '@/api/episodes';
import { webSocketService } from '@/utils/websocketService';
import { Episode } from '@/models/episode';
import { Recording } from '@/models/recording';

interface Column {
  label?: string;
  format?: (value: any) => string;
}

interface Item {
  id: string;
  attachedToType?: string;
  attachedToId?: string;
  title?: string;
  episodeNumber?: number;
  cardTitle?: string;
  filePath?: string;
  percentProgress?: number;
  uploadComplete?: boolean;
  [key: string]: any;
}

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  items: {
    type: Array as () => Item[],
    required: true,
  },
  columns: {
    type: Object as () => Record<string, Column>,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: 'item',
  },
});

const emit = defineEmits(['update', 'item-selected']);

const refmtItems = ref<Item[]>([]);

const columnHeaders = computed<Record<string, Column>>(() => {
  if (Object.keys(props.columns).length > 0) {
    return props.columns;
  }
  return props.items.length > 0 ? Object.keys(props.items[0]).reduce((acc, key) => {
    acc[key] = { label: key };
    return acc;
  }, {} as Record<string, Column>) : {};
});

const handleProgressUpdate = async (progressUpdate: any) => {
  console.log('items', refmtItems.value);
  console.log('update', progressUpdate);
  
  const item = refmtItems.value.find(item => item.filePath && item.filePath.includes(progressUpdate.path));
  if (item) {
    item.percentProgress = progressUpdate.progress;
    if (progressUpdate.progress === 100) {
      item.uploadComplete = true;
    }
  }

  const attachedToItems = refmtItems.value.filter(item => progressUpdate.path && progressUpdate.path.includes(item.id));
  attachedToItems.forEach(item => {
    item.percentProgress = progressUpdate.progress;
    emit('update', item);
  });
};

const getThing = async (item: Item) => {
  if (item.attachedToType === 'episode') {
    return await fetchEpisodeById(item.attachedToId!) as Episode;
  } else if (item.attachedToType === 'recording') {
    return await fetchRecordingById(item.attachedToId!) as Recording;
  } else {
    return null;
  }
};

const formatValue = (value: any, key: string) => {
  if (value === '' || value === null || value === undefined) {
    return 'Missing';
  } else if (columnHeaders.value[key].format) {
    return columnHeaders.value[key].format(value);
  }
  return value;
};

const selectItem = (item: Item) => {
  emit('item-selected', item, props.type);
};

const toProperCase = (str: string) => {
  if (!str) return str;
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

onMounted(async () => {
  webSocketService.addListener(handleProgressUpdate);
  
  const promises = await Promise.all(props.items.map(async item => {
    let attachedToThing
    let cardTitle = `${toProperCase(props.type.slice(0, -1))} ${item.id}`;
    if (props.type === 'recordings') {
      cardTitle = item.title || cardTitle;
    } else if (props.type === 'episodes') {
      cardTitle = `Episode ${item.episodeNumber}`;
    } else if (item.attachedToType === 'episode') {
      attachedToThing = await getThing(item) as Episode;
      cardTitle = `Episode ${attachedToThing?.episodeNumber}`;
    } else if (item.attachedToType === 'recording') {
      attachedToThing = await getThing(item) as Recording;
      cardTitle = attachedToThing?.title || cardTitle;
    }
    return { ...item, attachedToThing, cardTitle };
  }));

  refmtItems.value = promises;
  console.log('refmt', refmtItems.value);
});

onBeforeUnmount(() => {
  webSocketService.removeListener(handleProgressUpdate);
});
</script>
  
  
<style scoped>
.incomplete-items-list {
    margin: 30px;
}
h2 {
    margin: 50px
}

.table {
    margin: 0 20px;
    margin-top: 20px;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}
.pointer-cursor {
    cursor: pointer;
  }
  .card-header {
    font-size: 1.25rem;
    padding: 10px;
    text-align: center;
}
  .card {
    border-radius: 4px;
    margin: 10px;
}

.card-body {
    padding: 15px;
    text-align: left;
}

.card-footer {
    font-size: 0.875rem;
    padding: 10px;
    text-align: center;
}
</style>
