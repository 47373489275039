<template>
  <!-- <pre>{{ {file, fileExists} }}</pre> -->
  <div v-if="isLoading">
    Loading...
  </div>
  <div v-else-if="error">
    Error loading file: {{ error }}
  </div>
  <div v-else class="file-embed">
      {{ file.name }} 
      <a href="#" v-if="file.uploadComplete" @click="handleDownload" :class="['badge', { 'bg-warning': !file.downloaded }, { 'bg-success': file.downloaded }]">Download</a> 
      <a href="#" v-if="file.downloaded" @click="markAsNotDownloaded" class="badge bg-warning">Mark as Not Downloaded</a>
      <div v-if="!fileExists">Missing file</div>
      <div v-else-if="uploadProgress && uploadProgress < 100" class="progress">
        <div class="progress-bar" role="progressbar" :style="{ width: `${uploadProgress}%` }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Upload in progress: {{ uploadProgress }}%</div>
      </div>
      <div v-else-if="isImage" class="file-embed-image">
          <img :src="file.url.replaceAll(' ', '+')" :alt="file.name" class="img-fluid" />
      </div>
      <div v-else-if="isVideo" class="file-embed-video">
          <video controls :src="file.url.replaceAll(' ', '+')" class="video-fluid" >Your browser does not support the video element.</video>
      </div>
      <div v-else-if="isAudio" class="file-embed-audio">
          <audio controls :src="file.url.replaceAll(' ', '+')" :type="file.type" class="audio-fluid">Your browser does not support the audio element.</audio>
      </div>
      <div v-else-if="file.uploadComplete" class="file-embed-other">
          <a :href="file.url.replaceAll(' ', '+')" target="_blank" >{{ file.name }}</a>
      </div>
      
      <div v-else>Checking upload status...</div>
         
      <button v-if="file" class="btn btn-sm btn-danger" @click="deleteThisFile">Delete</button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watchEffect } from 'vue';
import { downloadFile, fetchFileById, undownloadFile, deleteFile } from '@/api/files';
import { FileUpload } from '@/models/file';
import { webSocketService, ProgressUpdate } from '@/utils/websocketService';
import axios from 'axios';
import { useAsyncState } from '@vueuse/core';

const props = defineProps<{ fileId: string }>();


const uploadProgress = ref(0);
const isAudio = ref(false);
const isImage = ref(false);
const isVideo = ref(false);
const receivedWSMessage = ref(false);
const awsCheck = ref({ status: 0 } as any);
const fileExists = ref(false);


const { state: file, isLoading, error, execute: loadFile } = useAsyncState(async () => {
  let f = {
    id: '',
    name: '',
    size: 0,
    type: '',
    attachedToType: '',
    attachedToId: '',
    file: {} as File,
    url: '',
    originalFileName: '',
    downloaded: false,
    uploadProgress: 0,
    category: '',
    created: new Date(),
    updated: new Date(),
    uploadComplete: false,
  } as FileUpload;
  f = await fetchFileById(props.fileId) as FileUpload;
  if (!f.uploadComplete) {
    await checkFileExists(f);
  } 
  isAudio.value = f.type?.startsWith('audio/') || f.type?.startsWith('wav') || false;
  isImage.value = f.type?.startsWith('image/') || false;
  isVideo.value = f.type?.startsWith('video/') || false;
  return f;
}, {} as FileUpload, { immediate: true });

const checkFileExists = async (fetchedFile: FileUpload) => {
  if (fetchedFile?.url) {
    try {
      console.log('Checking file exists:', fetchedFile.url);
      awsCheck.value = await axios.head(encodeURI(fetchedFile.url));
      console.log('AWS check:', awsCheck);
      uploadProgress.value = 100;
      fetchedFile.uploadComplete = true;
    } catch (error) {
      uploadProgress.value = uploadProgress.value > 0 ? uploadProgress.value : 0;
    }
  }
};

watchEffect(async () => {
  if (file.value?.url) {
    try {
      const awsCheck = await axios.head(encodeURI(file.value.url));
      console.log('AWS check:', awsCheck);
      // console.log('Upload progress:', uploadProgress.value);
      console.log('File upload complete:', file.value.uploadComplete);
      console.log('Received WS message:', receivedWSMessage.value);
      console.log('Is loading:', isLoading.value);
      console.log('Error:', error.value);
      // Correct logic for determining if file exists
      fileExists.value = awsCheck.status === 200 
        && file.value.uploadComplete 
        // && uploadProgress.value >= 100 
        && !receivedWSMessage.value 
        && !isLoading.value 
        && !error.value;
    } catch (err) {
      console.error('Error checking file existence:', err);
      fileExists.value = false;
    }
  } else {
    // Handle case when URL is not available
    fileExists.value = false;
  }
});


const handleProgressUpdate = (data: ProgressUpdate) => {
  if (file.value?.url?.includes(data.path)) {
    receivedWSMessage.value = true;
    uploadProgress.value = Math.max(uploadProgress.value, data.progress);
    if (data.progress === 100) {
      file.value.uploadComplete = true;
    }
  }
};

const handleDownload = async () => {
  try {
    if (file.value?.id) {
      await markAsDownloaded();
      const link = document.createElement('a');
      link.href = file.value.url.replaceAll(' ', '+');
      link.setAttribute('download', file.value.name);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    console.error('Error marking file as downloaded:', error);
  }
};

const markAsDownloaded = async () => {
  try {
    await downloadFile(file.value.id);
    if (file.value) {
      file.value.downloaded = true;
    }
  } catch (error) {
    console.error('Error marking file as downloaded:', error);
  }
};

const markAsNotDownloaded = async () => {
  if (file.value?.id) {
    try {
      await undownloadFile(file.value.id);
      if (file.value) {
        file.value.downloaded = false;
      }
    } catch (error) {
      console.error('Error marking file as not downloaded:', error);
    }
  }
};

const deleteThisFile = async () => {
  if (props.fileId) {
    if (confirm('Are you sure you want to delete this file?')) {
      await deleteFile(props.fileId);
    }
  }
};

onMounted(() => {
  loadFile();
  webSocketService.addListener(handleProgressUpdate);
});

onBeforeUnmount(() => {
  webSocketService.removeListener(handleProgressUpdate);
});
</script>


<style scoped>
.file-embed {
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
}

.file-embed img,
.file-embed video,
.file-embed audio {
  max-width: 100%;
  margin: 10px 0;
}

.file-embed-other a {
  color: #007bff;
  text-decoration: none;
}

.file-embed-other a:hover {
  text-decoration: underline;
}
.progress {
  margin: 10px 20px;
}
.progress-bar {
  background-color: #007bff;
  color: #fff;
  text-align: center;
}
.downloaded {
  border-color: lightgreen;
}
.not-downloaded {
  border-color: red;
}
</style>