import { Recording } from './../models/recording';
// router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import AuthCallback from '../components/AuthCallback.vue';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import store from '../store'; // Import the store
import EpisodeTemplate from '@/components/EpisodeTemplate.vue';
import EpisodeListView from '@/views/EpisodeListView.vue';
import RecordingListView from '@/views/RecordingListView.vue';
import UploadView from '@/views/UploadView.vue';
import OtherRecording from '@/components/OtherRecording.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: true, title: 'Home' }, // Protect this route
  },
  {
    path: '/auth/callback',
    component: AuthCallback,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { requiresAuth: false, title: 'Login' },
  },
  {
    path: '/upload-files',
    name: 'Upload Files',
    component: UploadView,
    meta: { requiresAuth: true, title: 'Upload Files' },
  },
  {
    path: '/episodes',
    name: 'Episodes',
    component: EpisodeListView,
    meta: { requiresAuth: true, title: 'Episodes' },
  },
  {
    path: '/episodes/:id',
    name: 'Episode',
    component: EpisodeTemplate,
    meta: { requiresAuth: true, title: 'Episode' },
    props: true,
  },
  {
    path: '/episodes/new',
    name: 'New Episode',
    component: EpisodeTemplate,
    meta: { requiresAuth: true, title: 'New Episode' },
  },
  {
    path: '/recordings',
    name: 'Recordings',
    component: RecordingListView,
    meta: { requiresAuth: true, title: 'Recordings' },
  },
  {
    path: '/recordings/:id',
    name: 'recording',
    component: OtherRecording,
    meta: { requiresAuth: true, title: 'Recording' },
    props: true,
  },
  {
    path: '/recordings/new',
    name: 'New Recording',
    component: OtherRecording,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard to check for auth
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;

  let title = to.meta.title;

  if (typeof title !== 'string') {
    title = 'Podcast Uploader';
  }

  if (to.params.id) {
    document.title = `${title} ${to.params.id}`;
  } else {
    document.title = title as string;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      await store.dispatch('checkAuth');

      if (!store.getters.isAuthenticated) {
        next({ name: 'Login' });
        return;
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;