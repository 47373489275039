import { Episode } from "@/models/episode";
import { FileUpload } from "@/models/file";
import { deleteFile } from "./files";
import { CaptivateEpisode } from "@/models/captivate";
// src/api/episodes.ts

import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;

// Fetch all episodes
export const fetchEpisodes = async (): Promise<Episode[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/episodes`);
        return response.data;
    } catch (error) {
        console.error('Error fetching episodes:', error);
        throw error;
    }
};

// Fetch a single episode by ID
export const fetchEpisodeById = async (id: string): Promise<Episode> => {
    try {
        const response = await axios.get(`${apiUrl}/api/episodes/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching episode:', error);
        throw error;
    }
};

export const fetchEpisodeByEpisodeNumber = async (episodeNumber: number): Promise<Episode> => {
    try {
        const response = await axios.get(`${apiUrl}/api/episodes/episode-number/${episodeNumber}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching episode:', error);
        throw error;
    }
}

export const fetchCaptivateEpisodeByEpisodeId = async (episodeId: string): Promise<CaptivateEpisode> => {
    try {
        const response = await axios.get(`${apiUrl}/api/episodes/${episodeId}/captivate`);
        return response.data;
    } catch (error) {
        console.error('Error fetching captivate episode:', error);
        throw error;
    }
}

export const createNewCaptivateEpisode = async (episodeId: string, captivateEpisode: CaptivateEpisode): Promise<CaptivateEpisode> => {
    try {
        const response = await axios.post(`${apiUrl}/api/episodes/${episodeId}/captivate`, captivateEpisode);
        return response.data;
    } catch (error) {
        console.error('Error creating captivate episode:', error);
        throw error;
    }
}


export const updateCaptivateEpisodeByEpisodeId = async (episodeId: string, episode: any): Promise<void> => {
    try {
        const response = await axios.put(`${apiUrl}/api/episodes/${episodeId}/captivate`, episode);
        console.log('Response here:', response);
        return response.data;
    } catch (error) {
        console.error('Error updating captivate episode:', error);
        throw error;
    }
}

export const createOrUpdateEpisode = async (episodeData: Partial<Episode>): Promise<Episode> => {
    const episodeId = episodeData.id || 0;
    if (episodeId) {
        try {
            await fetchEpisodeById(episodeId);
            return await updateEpisode(episodeId, episodeData);
        } catch (error) {
            //
        }
    }
    return await createEpisode(episodeData);
}

// Create a new episode
export const createEpisode = async (episodeData: Partial<Episode>): Promise<Episode> => {
    try {
        console.log('Creating episode:', episodeData);
        const response = await axios.post(`${apiUrl}/api/episodes`, episodeData);
        return response.data;
    } catch (error) {
        console.error('Error creating episode:', error);
        throw error;
    }
};

// Update an existing episode
export const updateEpisode = async (id: string, episodeData: Partial<Episode>): Promise<Episode> => {
    try {
        const response = await axios.put(`${apiUrl}/api/episodes/${id}`, episodeData);
        return response.data;
    } catch (error) {
        console.error('Error updating episode:', error);
        throw error;
    }
};

// Delete an episode
export const deleteEpisode = async (id: string): Promise<void> => {
    try {
        await axios.delete(`${apiUrl}/api/episodes/${id}`);
        const files = await getFilesForEpisode(id);
        files.forEach(async (file) => {
            await deleteFile(file.id);
        });
    } catch (error) {
        console.error('Error deleting episode:', error);
        throw error;
    }
};

export const getFilesForEpisode = async (episodeId: string): Promise<FileUpload[]> => {
    try {
        // console.log('Fetching files for episode:', episodeId);
        const response = await axios.get(`${apiUrl}/api/episodes/${episodeId}/files`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error fetching files for episode:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        throw error;
    }
}